import React, {useState, useEffect, Fragment} from 'react'
import RegularHero from '../components/Hero-Section/RegularHero';
import UncuttedImg from '../components/Uncutted Image Content/UncuttedImg';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';
import Associations from '../components/Associations Slider/Associations';
import CoachAssociations from '../components/Coaching Associations/CoachAssociations';
import Loader from '../components/Loader/Loader';
import { ProgramsItems } from '../components/Constants/ProgramsItems';

const Programs = () => {
    const [image, setImage] = useState('/images/creatives/programs-hero.jpg');
    const [loading, setLoading] = useState(true)

    useEffect(() => {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }, []);
  
    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth <= 620) {
          setImage('/images/creatives/programs-hero-mob.png');
        } else {
          setImage('/images/creatives/programs-hero.jpg');
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    const ProgramsModule = () => {
        return (
        <div className='quest_sp_programs_page'>
            <RegularHero 
              backgroundImage= {image}  
              text={'PROGRAMS'}
            />
            <UncuttedImg 
              imgURL={`/images/creatives/img-content-8.png`}
              otherScreen={true}
              heading={`BUILDING TOMORROW'S CHAMPIONS TODAY!`}
              paragraph={`The Quest Sports elementary program is a must for any child who wants to try track and field. The program is filled with fun, exploration, and exciting challenges for young developing athletes.`}
              button={true}
              listItems={ProgramsItems}
              buttonText={`Inquire Now`}
            />
            <RadialImgContent 
              heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
              paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
              who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
              sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
              Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
              buttonTitle={`Inquire Now`}
              buttonRoute={'/appointment-booking'}
              buttonIcon='/images/icons/golden-torch.svg'
              hoverBtnIcon='/images/icons/black-torch.svg'
              imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
            />
            <UncuttedImg 
              imgURL={`/images/creatives/img-content-9.png`}
              otherScreen={true}
              buttonRoute={'/track-field-club'}
              heading={`TRACK & FIELD`}
              color={'#F6F5F8'}
              anotherParagraph={`Quest Sports Track & Field Club's coaching staff of Olympians and Elite Coaches are focused and determined to take your child to the next level!
              Come and join us on our Quest of building NextGen Champions!`}
              paragraph={`As Quest Sports Track & Field Club enters a new era, our athletes are US scholarship bound, ready to take on the world with a strong foundation of Track & Field excellence .`}
              button={true}
              buttonText={`LEARN MORE`}
            />
            <Associations /> 
            <UncuttedImg 
                reverse={'flex-row-reverse'}
                imgURL={`/images/creatives/img-content-10.png`}
                otherScreen={true}
                heading={`SPEED TRAINING HUB`}
                color={'#F6F5F8'}
                colPaddingTop={'pt-2'}
                buttonRoute={'/speed-training-program'}
                anotherParagraph={`Speed is a valuable asset that athletes should focus on when testing and training for sports. Most sports require acceleration from a static state. Therefore an explosive 3 - 5 steps are of utmost importance. Appropriate training does improve running speed, even at the elite level.`}
                paragraph={`Most coaches in sports value speed highly. A faster athlete who can get to a ball faster than a competitor or even outrun a pursuer will always have an advantage.`}
                button={true}
                buttonText={`LEARN MORE`}
            />
            <CoachAssociations 
                heading={`Coaching ASSOCIATES`} 
                imgURL1={`/images/creatives/coach-1.png`} 
                imgURL2={`/images/creatives/coach-2.png`} 
            />
            <UncuttedImg  
                imgURL={`/images/creatives/img-content-11.png`}
                otherScreen={true}
                heading={`STRENGTH & CONDITIONING`}
                color={'#F6F5F8'} 
                // listItems={['lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum', 'lorem ipsum']}
                paragraph={`Quest Sports offers one of Ontario's strength and conditioning programs for young athletes. Athlete development starts from a young age by teaching the fundamentals with professional tips to ace the weightlifting training sessions.`}
                // anotherParagraph={`Does lifting weights affect the child growth? `}
                // para4={`What age group is essential for young athletes to begin weightlifting?`}
                para5={`Young athletes can begin weightlifting around seven or eight years old. If an athlete is mature enough to follow the right instructions, they are able to start weightlifting.`}
                para3={`Athletes will learn to use their own body weight to progressing to Olympic weightlifting techniques as their bodies mature.`}
                button={true}
                buttonRoute={'/strength-conditioning-gym'}
                buttonText={`LEARN MORE`}
            />
        </div>
    )}
  
  return (
    <Fragment>
       {loading ? <Loader /> : ProgramsModule()}
    </Fragment>
  )
}

export default Programs;