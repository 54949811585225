import React from 'react';
import './MLBAthlete.css';

const MLBAthlete = () => {
  return (
    <div className='mlb_athlete_component'>
        <div className='container'>
            <div className='mlb_info text-center'>
                <h1 className='mb-5'>Drafted MLB Athletes Trained Here</h1>   
            </div>
            <div className='row mt-3'>
                <div className='col-lg-3 col-md-6 mb-4'>
                    <a href='https://www.mlb.com/player/josh-naylor-647304' target='_blank' rel='noopener noreferrer'>
                        <div className='card player'>
                            <img src='/images/creatives/josh.png' alt='MLB Players' />
                            <div className='card-text text-center'>
                                <h2>Learn More</h2> 
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-lg-3 col-md-6 mb-4'>
                    <a href='https://www.milb.com/player/bo-naylor-666310' target='_blank' rel='noopener noreferrer'>
                        <div className='card player'>
                            <img src='/images/creatives/naylor.png' alt='MLB Players' />
                            <div className='card-text text-center'>
                                <h2>Learn More</h2> 
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-lg-3 col-md-6 mb-4'>
                    <a href='https://www.milb.com/player/denzel-clarke-672016' target='_blank' rel='noopener noreferrer'>
                        <div className='card player'>
                            <img src='/images/creatives/denzel.png' alt='MLB Players' />
                            <div className='card-text text-center'>
                                <h2>Learn More</h2> 
                            </div>
                        </div>
                    </a>
                </div>
                <div className='col-lg-3 col-md-6 mb-4'>
                    <a href='https://www.milb.com/player/tyler-black-672012' target='_blank' rel='noopener noreferrer'>
                        <div className='card player'>
                            <img src='/images/creatives/tyler.png' alt='MLB Players' />
                            <div className='card-text text-center'>
                                <h2>Learn More</h2> 
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MLBAthlete;