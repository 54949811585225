import React, {Fragment, useEffect, useRef, useState} from 'react';
import './ClockRadial.css';
import { Link } from 'react-router-dom';


const ClockRadial = ({reverse, heading, paragraph, requiredPara = false, buttonIcon, hoverBtnIcon, buttonTitle, imgUrl, listItems, buttonRoute}) => {
    const ref = useRef(null);
    const [btnHover, setBtnHover] = useState(false);
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    useEffect(() => {
        const observer = new IntersectionObserver(
        ([entry]) => {
            if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
            }
        },
        {
            threshold: 0.2,
        }
        );

        observer.observe(ref.current);

        return () => {
        observer.disconnect();
        };
    }, []);

    const AnimationFromLeft = isIntersecting ? 'slide-out' : '';
    const AnimationFromRight = isIntersecting ? 'slide-in' : '';

  return (
    <div className='img_effect'>
      <div className= {`radial_img_parent_clock`} ref={ref}>
        <div className='radial_img_content_component_clock'>
          <div className='container-fluid p-0'>
            <div className={`row d-flex m-0 ms-0 me-0 align-items-center gx-5`}>
              <div className={`col-md-12 position-relative order-2 order-lg-1 col-12 col-lg-7 mt-3 ${AnimationFromRight}`}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-1 col-0'></div>
                    <div className='col-lg-11 col-12'>
                      <h2 className='mb-3 slide-down mb-lg-4 col-12-text-center'>{heading}</h2>
                      <p className={`mb-3 slide-down mb-lg-4 col-12-text-center`}>{paragraph}</p>
                      <div className='mt-2 slide-down col-12-text-center'> 
                        {listItems ? listItems.map(element => {
                          return(
                            <div className='mb-3'> 
                              <h3 className='list-item-heading'>{element.heading}</h3>
                              {element.items.map(item => {return (<p className='list-item-para'>{item.name} <span>{item.title}</span></p>)})} 
                            </div>
                          )
                        }) : null}  
                        {buttonTitle && (
                          <Link to={!buttonRoute ? '/appointment-booking' : buttonRoute}>
                            <button onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                              {buttonTitle}{' '}
                              <span> 
                                <img className='img-fluid ml-2' src={!btnHover ? buttonIcon : hoverBtnIcon } />
                              </span>
                            </button>
                          </Link> 
                        )}
                      </div>
                      {requiredPara && <div className={`${requiredPara ? 'hide_p' : ''}`}></div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-12 hover-col p-0 m-0 order-1 d-flex justify-content-end align-items-center order-lg-2 col-12 col-lg-5 position-relative ${AnimationFromLeft}`}> 
                <img src={imgUrl} className='img-fluid img-icon' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClockRadial;