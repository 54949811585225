import React, { useState } from 'react';
import './SalesContactForm.css';
import { Settings } from '../../../inc/Settings';

const SalesContactForm = () => {
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        message: '', 
    }); 
    const [btnHover, setBtnHover] = useState(false);
    const [submitBtnHover, setSubmitBtnHover] = useState(false);

    const buttonIcon = '/images/icons/golden-torch.svg';
    const hoverBtnIcon = '/images/icons/black-torch.svg';
 
    let contactAPIFired = false

    const onInputChangeHandler = (e) => {
        setFormData((prevState) => {
            return {
                ...prevState,
                [e.target.name]: e.target.value
            }
        })
    }

    const HubSpotAPIExecution = (payload) => {
        const url = `/hubspot-contacts`;
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json' 
            },
            body: JSON.stringify(payload)
        })
        .then(data => {
            if (data?.id) { 
                Settings.alert('Message sent successfully!');
                contactAPIFired = true;
                setFormData({
                    first_name: '',
                    last_name: '',
                    email: '',
                    phone: '',
                    message: '', 
                })
            } else if (data?.status === "error" && data?.message.includes("already exists")) { 
                Settings.alert('This email is already registered. Please use a different email.', 'error');
                contactAPIFired = false;
            } else {
                Settings.alert('Message could not be processed!', 'error');
                contactAPIFired = false;
            }
        })
        .catch(error => {
            Settings.alert('Message couldnt Process!', 'error')
            contactAPIFired = false 
        });
    }

    const TwilioApiCall = (payloadBody) => {
        const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
        const authToken = '11028ed967e7fb99d50a058f9f488470';
        const fromNumber = '+16476991144';
        const toNumber = '+16477709124';
    
        const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
            },
            body: new URLSearchParams({
                From: fromNumber,
                To: toNumber,
                Body: payloadBody
            })
        })
        .then(response => {  
            console.log('successfully sented message to Donna')
        })
        .catch(error => { 
            console.log('not successfully sented message to Donna')
        });
    }

    const executeSubmitAPI = () => {
        if(!formData.first_name){
            Settings.alert('First Name field missing!', 'error')
            return;
        }
        if(!formData.last_name){
            Settings.alert('Last Name field missing!', 'error')
            return;
        } 
        if(!formData.phone){
            Settings.alert('Phone field missing!', 'error')
            return;
        }
        if(!formData.email){
            Settings.alert('Email field missing!', 'error')
            return;
        }
        if(!formData.message){
            Settings.alert('Email field missing!', 'error')
            return;
        }
        if(contactAPIFired){
            return;
        }

        contactAPIFired = true 
   
        const body = 
        `Client - Information - (From Track & Field Landing Page);
   
          Phone: ${formData.phone}  
          Message: ${formData.message} 
 
          ----
          Message from Quest Sports Club Canada.
        `;

        const twilioPayload =
        `Client - Information - (From Track & Field Landing Page);

          Name: ${formData.first_name}
          Last Name: ${formData.last_name} 
          Phone: ${formData.phone}
          Email: ${formData.email} 
          Message: ${formData.message} 
 
          ----
          Message from Quest Sports Club Canada.
        `;
 
        const payload = {
            properties: {
                email: formData.email,
                firstname: formData.first_name,
                lastname: formData.last_name, 
                client_details: body?.trim()
            }
        };
    
        HubSpotAPIExecution(payload);
        TwilioApiCall(twilioPayload);
    }
    return (
        <div className='sales_contact_form'>
            <div className='container'>
                <div className='row align-items-center'> 
                    <div className='col-lg-6 mb-5 col-12'>
                        <h2>CHAMPIONS OFF THE FIELD</h2>
                        <p>Quest Sports Athletes are leaders known for their kindness and respect toward one another. We love our community and ensure our athletes learn the power of giving back and serving their local communities.</p>
                        <a href='https://site.arboxapp.com/shop?identifier=CyvWUKzO&referer=SITE&whitelabel=Quest-Sports&lang=en' target='_blank' rel='noopener noreferrer'>
                            <button onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                                BECOME A MEMBER
                                <span> 
                                    <img className='img-fluid ml-2' src={btnHover ? buttonIcon : hoverBtnIcon } />
                                </span>
                            </button>
                        </a> 
                    </div> 
                    <div className='col-lg-6 col-12 d-flex justify-content-center justify-lg-content-end'>
                        <div className='contact_details'>
                            <div className='container mt-4'>
                                <div className='row form-fields-contact'>
                                    <div className='col-lg-6 col-12 fields-contact'>  
                                        <input onChange={onInputChangeHandler} value={formData.first_name} name='first_name' type="text" id="fname" placeholder='First Name' /> 
                                    </div>
                                    <div className='col-lg-6 col-12 fields-contact'>  
                                        <input onChange={onInputChangeHandler} value={formData.last_name} name='last_name' type="text" id="lname" placeholder='Last Name' /> 
                                    </div>
                                    <div className='col-lg-12 col-12 fields-contact'>  
                                        <input onChange={onInputChangeHandler} value={formData.email} name='email' type="email" id="email" placeholder={`Email`} /> 
                                    </div>
                                    <div className='col-lg-12 col-12 fields-contact'>  
                                        <input onChange={onInputChangeHandler} value={formData.phone} name='phone' type="text" id="phone" placeholder={`Phone`} /> 
                                    </div>
                                    <div className='col-lg-12 col-12 fields-contact'>  
                                        <textarea onChange={onInputChangeHandler} value={formData.message} name='message' type="text" id="message" placeholder='Message' /> 
                                    </div> 
                                    <button onClick={executeSubmitAPI} onMouseLeave={() => setSubmitBtnHover(false)} onMouseEnter={() => setSubmitBtnHover(true)} className='skew-btn ml-3 mt-4'>Submit <span><img className='img-fluid ml-2' src={!submitBtnHover ? buttonIcon : hoverBtnIcon} /></span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesContactForm;