import React, {useState,useEffect} from 'react';
import './BlogsComp.css';
import { BlogsList } from './AllBlogs';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import axios from "axios"
import { Spinner } from 'react-bootstrap';



let defaultBlogObj ;

const BlogsComp = ({LoadBtn = false}) => {
    const [btnHover, setBtnHover] = useState(false)
    const [blogs, setBlogs] = useState(null);

    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    let blogAPICalled = false;
  
    useEffect(() => {
      getAllBlogsListing();
    }, []);
    
    const getAllBlogsListing = () => {
        if (blogAPICalled) return;
        blogAPICalled = true;
    
        setLoading(true);
        axios
          .get("/api/blogs")
          .then((res) => {
            if (res.data.status === "success") {
              let Updated_data_Blogs = [];
              const apiBlog = res.data.data;
              apiBlog.forEach((item) => {
                Updated_data_Blogs.push({
                  id: item.blog_id,
                  main_heading: item.title,
                  paragraph: item.brief_paragraph,
                  logo: "/favicon.png",
                  entity: "Creative Squad",
                  date: item.published_date,
                  slug_url: item.slug_url,
                  category: item.category,
                  blog_creative: item.blog_image,
                });
              });
              setBlogs({localBlog : BlogsList, apiBlog: Updated_data_Blogs});
              
              defaultBlogObj = Updated_data_Blogs;
              setLoading(false);
            }
              
          })
          .catch((err) => {
            console.log("eror =>",err);
            setBlogs({localBlog : BlogsList, apiBlog: []});
            setLoading(false);
          });
      };
    
     
  return (
    <div className='quest_blogs_section'>
        <div className='container'> 
            <div className='row'>  
                <div className='col-12'>
                   <h1 className='heading-arctcls'>ARTICLES</h1>
                </div>
                {loading?
                (<div className="w-100 text-center mb-5">
                    <Spinner
                    style={{ color: "#E9D06B", width: "120px", height: "120px" }}
                    />
                </div>)  
                :
                <>
                {blogs.localBlog.map((card) => (
                    <div className='col-lg-4 col-md-6 col-12 mb-4' key={card.id}> 
                        <Link to={`/blogs/${card.str_id}`} className='link-tag-card'>
                            <div className="card">
                                <div className='row text-start'>
                                    <div className='col-12'>
                                        <img src={card.image} className='img-fluid thumbnail-icon mb-3' alt={card.title} />
                                    </div>
                                    <div className='col-12'>
                                        <div className='blog_short_info'>
                                            <h1>{card.heading}</h1> 
                                            {/* <p>{card.paragraph}</p> */}
                                            <div className="para-normal" dangerouslySetInnerHTML={{ __html: card.paragraph }}></div>
                                        </div>
                                        <div className='timing_info'>
                                            <div className="line mb-3"></div>
                                            <p>{card.dates} <span className='ms-3'>  {card.time}</span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))} 
                {
                    blogs.apiBlog.map((ele)=>(
                        <div className='col-lg-4 col-md-6 col-12 mb-4' key={ele.id}> 
                            <Link to={`/blogs/${ele.slug_url}`} className='link-tag-card'>
                                <div className="card">
                                    <div className='row text-start'>
                                        <div className='col-12'>
                                            <img src={ele.blog_creative} className='img-fluid thumbnail-icon mb-3' alt={ele.main_heading} />
                                        </div>
                                        <div className='col-12'>
                                            <div className='blog_short_info'>
                                                <h1>{ele.main_heading}</h1> 
                                                {/* <p>{ele.paragraph}</p> */}
                                                <div className="para-normal" dangerouslySetInnerHTML={{ __html: ele.paragraph }}></div>
                                            </div>
                                            <div className='timing_info'>
                                                <div className="line mb-3"></div>
                                                <p>{ele.date} </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                }
                </>
                }
                {LoadBtn && 
                    (
                        <div className='col-12 text-center'>
                            <button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-5 mb-3'>LOAD MORE <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>
                        </div> 
                    )
                }
            </div>
        </div>
    </div>
  )
}

export default BlogsComp;