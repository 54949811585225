import React, { Fragment, useState } from 'react';
import './PaymentRoutes.css'; 
import MeetFounder from '../../components/Meet Founder/MeetFounder';
import { Settings } from '../../inc/Settings';

const DonationPaymentRoute = () => {
    const [loading, setLoading] = useState(false);
    const [donationAmount, setDonationAmount] = useState('');

    const handleDonateNowFunctionality = async () => {
        if(!donationAmount) {
            Settings.alert('Please provide Donation Amount!', 'error');
            return;
        };

        try {
            setLoading(true)
            const response = await fetch(`/create-checkout-session-next-gen-donations`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "item": {
                        "amount": donationAmount
                    }
                })
            });
            const data = await response.json();
  
            if (response.ok) {
                localStorage.setItem('session_id', data.session_id); 
                window.location.href = data.url;
                setLoading(false)
            }
        } catch (error) {
            Settings.alert('Apologies, something went wrong from our side!', 'error') 
            setLoading(false)
        }
    };

    const DonationModuleWrapper = () => {
        return (
            <div className='donation-input-wrapper'>
                <label htmlFor="Donation Amount"><b>Please Enter Donation Amount:</b></label>
                <div className='row'>
                    <div className='col-lg-8'>
                        <input
                            type="number"
                            id="donation_amount"
                            placeholder="CAD $"
                            value={donationAmount}
                            onChange={(e) => setDonationAmount(e.target.value)}
                        />
                    </div>    
                </div>
                <button disabled={loading} onClick={handleDonateNowFunctionality}>Donate Now</button>
                <img className='img-fluid' src='/images/creatives/donation-hands.webp' />
            </div>
        )
    };

    return (
        <div className='donation-payment-gateway-wrapper'>
            <Fragment>
                <MeetFounder donation={true} /> 
                {DonationModuleWrapper()}
            </Fragment>
        </div>
    )
};

export default DonationPaymentRoute;