import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import BlogsPortalListings from "../../components/Blogs & Landing Page/BlogsPortalListings";

const BlogsScreen = () => {
  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      {/* <h1 style={{ color: "black" }} className="h1_main ps-5 pt-5 pb-5">
        Blogs Listing
      </h1> */}
      <div className="container text-center py-5">
        <h2>Blogs Listing</h2>
        <p>Quest Sport Blog Portal</p>
      </div>
      <BlogsPortalListings />
    </Fragment>
  );
};

export default BlogsScreen;
