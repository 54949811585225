export const BackgroundArr = [
    {heading: 'OLYMPIANS', items: [
        { name: 'Donna Clarke:', title: 'Heptathlete' },
        { name: 'Nikkita Holder:', title: '100m Hurdles' }, 
    ]},
    {heading: 'CERTIFIED COACHES', items: [
        { name: 'Donna Clarke:', title: 'Head Coach Multi Events & Hurdles' },
        { name: 'Clive Foster:', title: 'Head Coach High School Boys' },  
        { name: 'Nikkita Holder:', title: 'Head Coach Elementary Kids Program' }
    ]}, 
    {heading: 'EVENT SPECIALISTS', items: [
        { name: 'Donna Clarke:', title: 'Multi Events & Hurdles' }, 
        { name: 'Chris Martin:', title: 'Jumps' }, 
        { name: 'Nikkita Holder:', title: 'Sprint Hurdles' },  
        { name: 'Clive Foster:', title: 'Sprints and 400m' }, 
        { name: 'Coach L:', title: 'Middle Distance and Cross Country' }, 
    ]},
]
