import React, { useEffect } from 'react';
import { useLocation  } from 'react-router-dom'

const ClientPaymentRoute = () => {
    const urlParams = new URLSearchParams(useLocation().search);
    const baseball_speed_training = urlParams?.get('baseball');
    const indoor_track_field = urlParams?.get('track_field');
    const indoor_track_field_highschool = urlParams?.get('track_field_highschool');
    const nextgen_baseball_speed_training = urlParams?.get('nextgen');
    
    useEffect(() => { fetchCheckoutSession() }, [])

    const fetchCheckoutSession = async () => {
        try {
            const response = await fetch(`${!nextgen_baseball_speed_training && !indoor_track_field && !baseball_speed_training && !indoor_track_field_highschool ? '/create-checkout-session' : '/create-checkout-session-next-gen'}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "items": [
                        {
                            "type": !baseball_speed_training && !nextgen_baseball_speed_training && !indoor_track_field && !indoor_track_field_highschool ? "track_field_elementary" : ( baseball_speed_training ? "baseball_speed" : "indoor_track_field"),
                            "id": !baseball_speed_training && !nextgen_baseball_speed_training && !indoor_track_field ? (indoor_track_field_highschool ? 2 : 2) : 1,     
                            "quantity": 1   
                        } 
                    ]
                })
            });
            const data = await response.json();
  
            if (response.ok) {
                localStorage.setItem('session_id', data.session_id); 
                window.location.href = data.url;
            }
        } catch (error) {
          console.error('Error creating checkout session', error);
        }
    }
    return (
        <div className='navigating_message'>You are been redirected to our secured payment gateway....</div>
    )
};

export default ClientPaymentRoute;