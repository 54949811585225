import React, { Fragment, useEffect, useRef, useState} from 'react';
import './UncuttedImg.css';
import { Link } from 'react-router-dom';

// for reversing orders - flex-row-reverse

const UncuttedImg = ({
    imgURL, 
    trackField, 
    speedTraining, 
    strengthConditioning,
    otherScreen, 
    buttonRoute,
    color = '#fff', 
    para5,
    reverse,
    para4,
    heading, 
    aboutSection,
    listItemSpeed,
    paragraph, 
    anotherParagraph, 
    button, 
    buttonText, 
    targetSection,
    listItems,
    topHeading,
    para3, 
    speedTraining2,
    buttonStr,
    buttonTextStr,
    colPadding = 'p-4',
    colPaddingTop = 'pt-5'
}) => {
    const [btnHover, setBtnHover] = useState(false)
    const ref = useRef(null); 
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
          }
        },
        {
          threshold: 0.2,
        }
      );
  
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, []);

    const DirectToEnquery = (e) => { 
        e.preventDefault(); 
        document.querySelector('#contact-form').scrollIntoView({
          behavior: 'smooth'
        });
    }
  
    const AnimationFromLeft = isIntersecting ? 'slide-out' : '';
    const AnimationFromRight = isIntersecting ? 'slide-in' : '';
  return (
    <div className='un_cutted_img_component' style={{background: color, paddingBottom: color != '#fff' ? '10.2rem' : '0rem'}} ref={ref}>
        <div className='container'>
            {topHeading && (<h1 className='top-heading text-center mb-4'>ARE YOU THE ONE?</h1>)}
            <div className={`row ${reverse} d-flex align-items-center`}>
                <div className={`col-lg-6 col-12 ${reverse ? AnimationFromLeft : AnimationFromRight}`}>
                    <img src={imgURL} className='img-fluid' />
                </div>
                <div className={`col-lg-6 ${colPaddingTop} ${colPadding} ${reverse ? AnimationFromRight : AnimationFromLeft} col-12`}>
                    {trackField && (
                        <Fragment>
                            <div className='content_1'>
                                <h1>QUEST SPORTS TRACK & FIELD CLUB A HIDDEN GEM</h1>
                                <p className='mb-4'>Quest Sports Track & Field Club is a hidden gem that has emerged as the go to club for development, as we have produced some of the best young talent in Ontario! At Quest Sports we take commitment and development seriously as we mould, shape and nurture the Next Generation of Canadian Track & Field talent.</p>
                            </div>
                            <div className='content_2'>
                                <h1>CHAMPIONS OFF THE FIELD</h1>
                                <p className='mb-4'>Quest Athletes are leaders known for their kindness and respect toward one another. We love our community and ensure our athletes learn the power of giving back and serving their local communities.</p>
                            </div>
                            <div className='content_2'>
                                <h1>CHAMPIONS ON THE FIELD</h1>
                                <p className='mb-4'>Quest Athletes are well-rounded and have been developed with and excellent foundation. They are the some of the most sought after athletes because of their refined skills set. Through our Talent Identification Process, we carefully identify every child's God-given talents, putting them in positions where they will find their success.</p>
                            </div> 
                            <div className='action_btn'>
                               <a href={targetSection}><button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-3'>BECOME A MEMBER <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button></a>
                            </div>
                        </Fragment>
                    )}
                    {aboutSection && (
                        <Fragment>
                            <div className='content_1'>
                                <h1>WE BELIEVE IN THE IMPOSSIBLE</h1>
                                <p className='mb-4'>Sydney Mclaughlin Lavourne has been chosen as Quest Sports' role model because she embodies Values & Beliefs that “All Things Are Possible.” As an athlete, Sydney demonstrates humility, talent, strong work, character, inner strength and inner beauty. At Quest Sports, we strive to embody these principles.</p>
                            </div>
                        </Fragment>
                    )}
                    {speedTraining && (
                        <Fragment>
                            <div className='content_1_speed_strength_conditioning'>
                                <h1 className='mb-3'>WELCOME TO QUEST SPORTS SPEED TRAINING HUB</h1> 
                                <p className='mb-3'>Quest Speed Coaches teach all the movement patterns to condition the neuromuscular system to improve the firing patterns of fast-twitch muscle fibers. The Speed Lab is a great training hub for baseball, basketball, football, and soccer athletes.</p>
                                <p className='mb-3'>Quest Speed Coaches teach that accelerating quickly and powerfully over the first few strides is one of the most critical skills that all athletes need to make the most improvements on their overall speed.</p>
                                { listItemSpeed && listItemSpeed.map((item) => {
                                    return (
                                        <ul className='list-items'>
                                            <li><span className='bullet-point'></span>{item}</li>
                                        </ul>
                                    )}
                                )}
                                {buttonStr ? (
                                    <Fragment>
                                        <a href={targetSection}> 
                                            <button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-3'>{buttonTextStr} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>  
                                        </a>
                                    </Fragment>
                                )
                                : null}
                            </div>
                        </Fragment>
                    )} 
                    {speedTraining2 && (
                        <Fragment>
                            <div className='content_1_speed_strength_conditioning'>
                                <h3 className='mb-3'>WHAT ARE COACHES LOOKING FOR?</h3> 
                                <p className='mb-3'>Most coaches in sports highly value speed. A faster athlete who can get to a ball faster than a competitor or even outrun a pursuer will always have an advantage.</p>
                                <h3 className='mb-3'>WHY SPEED TRAINING?</h3> 
                                <p className='mb-3'>Speed is a valuable asset that athletes should focus on when testing and training for sports. Most sports require acceleration from a static state. Therefore an explosive 3 - 5 steps are of utmost importance. Appropriate training does improve running speed, even at the elite level.</p>
                                { listItemSpeed && listItemSpeed.map((item) => {
                                    return (
                                        <ul className='list-items'>
                                            <li><span className='bullet-point'></span>{item}</li>
                                        </ul>
                                    )}
                                )}
                                {buttonStr ? (
                                    <Fragment>
                                        <a href={targetSection}> 
                                            <button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-1'>{buttonTextStr} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>  
                                        </a>
                                    </Fragment>
                                ) : null}
                            </div>
                        </Fragment>
                    )}
                    {strengthConditioning && (
                        <Fragment>
                            <div className='content_1_speed_strength_conditioning'>
                                <h1 className='mb-3'>OLYMPIC WEIGHTLIFTING</h1>
                                <h3>Does lifting weights affect a child's growth?</h3>
                                <p className='mb-2'>"There's no evidence that weight lifting stunts growth," she said. "In fact, resistance training can help build coordination and strength in young athletes, and there's a strong push right now for young female athletes to lift weights in the hope that it may offer more injury prevention</p>
                                <p className='mb-2'><span className='highlight_para_spn'>Carol Mack, D.P.T., C.S.C.S., strength coach and doctor of physical therapy</span></p>
                                <h3>What age should kids lift weights?</h3>
                                <p className='mb-2'>"Young athletes can begin a strength training program around the same time they begin to play organized sports, as early as 7 or 8 years old if they express interest and are mature enough to follow directions"</p>
                                <p className='mb-2'><span className='highlight_para_spn'>Jacob Rivera, CSCS, USAW, a certified strength and conditioning specialist with Children's Health℠ Andrews Institute.</span></p>
                                <h3 className='mb-2'>RORY VAN ULFT</h3>
                                <p>A young weightlifting phenom is breaking records and misconceptions about weightlifting for kids.</p>
                                <a href={targetSection}><button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-1'>READ MORE <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button></a> 
                            </div>
                        </Fragment>
                    )}
                    {otherScreen && (
                        <Fragment>
                            <div className='content_1_speed_strength_conditioning'>
                                <h1 className='mb-3'>{heading ? heading : null}</h1> 
                                <p className='mb-3'>{paragraph ? paragraph : null}</p> 
                                <p className='mb-3'>{anotherParagraph ? anotherParagraph : null}</p> 
                                <p className='mb-3'>{para3 ? para3 : null}</p>
                                <p className='mb-3'>{para4 ? para4 : null}</p>
                                <p className='mb-3'>{para5 ? para5 : null}</p>
                                {listItems && (
                                    <ul className='list-items'>
                                        { listItems.map(element => {return (<li><span className='bullet-point'></span>{element}</li>)}) }
                                    </ul>
                                )}
                                {button ? (
                                    <Fragment>
                                        {buttonRoute && <Link to={buttonRoute}><button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-4'>{buttonText} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button></Link>}
                                        {!buttonRoute && <button onClick={DirectToEnquery} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn mt-4'>{buttonText} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button> }
                                    </Fragment>
                                 ) : null}
                            </div>
                        </Fragment>
                    )}
                </div>
            </div>
        </div>
    </div>
  )
}

export default UncuttedImg;