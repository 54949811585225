import React, {useState, useEffect, useRef} from 'react';
import './MeetFounder.css';

const MeetFounder = ({donation=false}) => {
    const ref = useRef(null); 
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
          }
        },
        {
          threshold: 0.2,
        }
      );
  
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, []);
  
    const AnimationFromLeft = isIntersecting ? 'slide-out' : '';
    const AnimationFromRight = isIntersecting ? 'slide-in' : '';
  return (
    <div className='founder_about_component d-flex justify-content-center align-items-center' ref={ref}>
        <div className="container">
            <div className="row">
                <div className={`col-md-12 col-lg-5 d-flex justify-content-center align-items-start order-md-1 mb-md-0 ${AnimationFromRight}`}>
                  <img src={!donation ? '/images/creatives/donna.svg' : '/images/creatives/donation.webp'} style={{height: donation && 'auto'}} className="img-fluid founder-icon" alt="Image" />
                </div>
                <div className={`col-md-12 col-lg-7 order-md-2 d-flex align-items-center ${AnimationFromLeft}`}>
                    <div className='col-12 donna-content text-start'>
                      <h1 className='mb-4'>{donation ? `DOROTHY'S LEGACY` : 'MEET THE FOUNDER'}</h1>
                      <p>
                        {donation ? `Dorothy had a deep affection for children and sports, which she merged with her passion for contributing to their development through NexGEN Sports for Kids. She was a committed supporter of underserved youth, believing in healthy lifestyles and encouraging children to discover their talents. Dorothy left an indelible mark on the NexGEN community, inspiring numerous young individuals to pursue their athletic dreams with confidence and determination.` : 'Donna Clarke is an Olympian who discovered her love for track and field during her last years of high school. She started training for the heptathlon six months after switching from competing as a provincial gymnast and ended up competing at the 1984 Olympic Games. Despite being offered many scholarships to attend schools in the US, she chose to attend the University of Toronto and trained for the heptathlon with her coach Andy Higgins. With his help, she discovered that her favourite events were in the sprints, hurdles and long jump.'}  
                      </p>
                      <p>
                      {donation && `Rather than sending flowers, we invite you to make a meaningful contribution to the NexGen Sports Development Community for children to pay tribute to Dorothy's legacy. Your generous support will help us positively impact young children's lives and empower them to develop their full potential through sports.`}
                      </p>
                      <p> 
                        {donation ? `To learn more about NexGen Kids please go to ` : `
                          A highlight of her career was breaking the Canadian long jump record at the Canadian Championships with a long jump of 6.61m, and again on the same day, with a 6.72m jump.

                          After she retired from competitive sports and started Quest Sports Track and Field Club to pass on her knowledge to the future of Canadian athletics. The club provides focused training and attention to detail for athletes, with the goal of achieving individual success.`
                        }
                        {donation && <a href='https://www.nexgenkids.com' target='_blank'>NexGen Kids.</a>}
                        {donation && ` You can donate through an email transfer to nexgenkidscan@gmail.com or send your contribution through the button below.`}
                      </p>
                      {!donation && 
                        <div className='col-12 d-flex justify-content-end'>
                          <div className='d-flex align-items-center'>
                            <img src={'/images/creatives/donna-sign.png'} className="img-fluid float-md-end donna-sign-logo" alt="donna-sign" />
                          </div>
                        </div>
                      }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MeetFounder;