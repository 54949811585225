import React, { useState } from 'react';
import './QualityExp.css';
import {Types} from './ExperienceTypes';
import { Link } from 'react-router-dom';

const QualityExp = ({targetSection}) => {
    const [btnHover, setBtnHover] = useState(Array(Types.length).fill(false));
  return (
    <div className='offering_quality_experience'>
        <div className='container-fluid'>
            <div className='quality_experience text-center mb-5'>
                <h1>WE OFFER QUALITY & EXPERIENCE</h1>
            </div> 
        </div>
        <div className='container'> 
            <div className='row'>
                {Types.map((card, index) => (  
                    <div className={card.responsive}>
                        <div key={card.id} className="card text-center align-items-center mb-5">
                            <div className='row card-h text-center'> 
                                <div className='col-12'>
                                    <img src={card.image} className='img-fluid thumbnail-icon p-0 mb-3' alt={card.title} />
                                </div>
                                <div className='col-12'>
                                    <div className='quality_exp mb-5'>
                                        <h2>{card.heading}</h2> 
                                        <p>{card.paragraph}</p> 
                                        <ul className='list-items'>
                                            {card.listItems.map((item) => {
                                                return ( 
                                                    <p>{item}</p> 
                                                )
                                            })}
                                        </ul>
                                    </div>  
                                </div>
                                <div className='col-12 mb-auto'>
                                    <a href={targetSection}>
                                        <button
                                            onMouseEnter={() => {
                                                const newBtnHover = [...btnHover];
                                                newBtnHover[index] = true;
                                                setBtnHover(newBtnHover);
                                            }}
                                            onMouseLeave={() => {
                                                const newBtnHover = [...btnHover];
                                                newBtnHover[index] = false;
                                                setBtnHover(newBtnHover);
                                            }}
                                            className='skew-btn mb-3'
                                        >
                                        Sign Up <span><img className='img-fluid join-btn ml-2' src={!btnHover[index] ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span>
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </div> 
                    </div> 
                ))}
            </div>
        </div>
    </div>
  )
}

export default QualityExp;