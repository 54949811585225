import React, {useState, useEffect, useRef, Fragment} from 'react';
import './CutImageContent.css';
import { Link } from 'react-router-dom';

// flex-row-reverse - for reversing an order
const CutImageContent = ({
    reverse, 
    paragraph, 
    removeBtn,
    programsRoute,
    targetSection,
    ExtraLogo, 
    marginTop = '', 
    strengthConditioningListItems = false,
    dimensionsExtra, 
    highlightedParagraph2, 
    paragraphLogo, 
    paragraph2, 
    breakParagraph2, 
    rowListItemHeading4, 
    rowListItems4, 
    ExtraLogoBeforeBtn, 
    dimensions = {width: '100px', height: '90px', marginTop: '1.8rem'}, 
    ExtraLogoPath, 
    btnReq = true, 
    rowListItemHeading1, 
    rowListItemHeading2, 
    rowListItemHeading3, 
    rowListItems1, 
    rowListItems2, 
    rowListItems3, 
    landingComp = false,
    rowItems, 
    DashedPara1, 
    DashedPara2, 
    DashedPara3, 
    swoosh= true, 
    breakParagraph, 
    buttonTitle, 
    heading, 
    highlightedParagraph, 
    listItems, 
    imgUrl, 
    buttonRoute
}) => {

    const ref = useRef(null);
    const [btnHover, setBtnHover] = useState(false);
    const [isIntersecting, setIsIntersecting] = useState(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsIntersecting(true);
            observer.disconnect();
          }
        },
        {
          threshold: 0.2,
        }
      );
  
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, []);

    const DirectToEnquery = (e) => { 
        e.preventDefault(); 
        document.querySelector('#contact-form').scrollIntoView({
          behavior: 'smooth'
        });
    }
  
    const AnimationFromLeft = isIntersecting ? 'slide-out' : '';
    const AnimationFromRight = isIntersecting ? 'slide-in' : '';

    return (
    <div className='parent_img_cn'>
        <div className={`img_by_cn_section`} style={{marginTop: `${marginTop ? '6.5rem' : ''}`}} ref={ref}>
            <div className='container-fluid px-0 overflow-x-hidden'>
                <div className={`row m-0 ms-0 me-0 ${reverse} gx-5 align-items-center`}>
                    <div className={`col-lg-6 mb-4 ${reverse && landingComp && 'd-flex justify-content-lg-end'} mb-lg-0 col-md-12 col-sm-12 p-0 ${reverse ? AnimationFromLeft : AnimationFromRight}`}>
                        <img className='img-fluid content_img px-0 p-0' src={imgUrl} alt='content_img' />
                    </div>
                    {landingComp && <div className='col-lg-1 col-0'></div>}
                    <div className={`${!landingComp && 'col-lg-5'} col-md-12 col-sm-12 px-0 ${landingComp && 'col-lg-5 mt-5'}`}>
                        <div className='container'>
                            <div className='row'>
                                <div className={`col-lg-12 col-md-12 col-12 ${reverse ? AnimationFromRight : AnimationFromLeft}`}> 
                                    <div className='header-container'>
                                        <h1 className='mb-3 mb-lg-3 col-12-text-center'>
                                            {heading}
                                        </h1>
                                        {swoosh && ""}
                                    </div>   
                                    {strengthConditioningListItems && ( 
                                        <ul className='list-items mt-0' style={{ display: 'inline-flex', flexWrap: 'wrap' }}>
                                            { ( listItems) ? listItems.map(element => {return (<li className='mr-3 list-item-str'>{element}</li>)}) : null }
                                        </ul> 
                                    )}
                                    <p className='mb-3 mb-lg-3 col-12-text-center highlighted-para'>
                                        {highlightedParagraph}
                                    </p>
                                    <p className='mb-3 mb-lg-3 col-12-text-center'>{paragraph}</p>
                                    <p className='mb-2 mb-lg-0 col-12-text-center'>
                                        {paragraphLogo}
                                    </p>
                                    {ExtraLogo && <img src={ExtraLogoPath} className={`img-fluid`} style={dimensions} />}
                                    {breakParagraph && <p className='mb-3 mb-lg-3 col-12-text-center'>
                                        {breakParagraph}
                                    </p>}
                                    {highlightedParagraph2 &&  <p className='mb-3 mb-lg-3 mt-1 col-12-text-center highlighted-para'>
                                        {highlightedParagraph2}
                                    </p>}
                                    {paragraph2 && <p className='mb-2 mb-lg-3 col-12-text-center'>
                                        {paragraph2}
                                    </p>}
                                    {rowItems && (
                                        <div className='row'>
                                            <div className='col-lg-6 col-md-12 mb-3 mb-lg-3'>
                                                <p className='row-list-item-heading'>{rowListItemHeading1}</p>
                                                {rowListItems1.map((item1) => {
                                                    return (
                                                        <p className='li-item'>{item1}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className='col-lg-6 col-md-12 mb-3 mb-lg-3'>
                                                <p className='row-list-item-heading'>{rowListItemHeading2}</p>
                                                {rowListItems2.map((item2) => {
                                                    return (
                                                        <p className='li-item'>{item2}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className='col-lg-6 col-md-12 mb-3 mb-lg-0'>
                                                <p className='row-list-item-heading'>{rowListItemHeading3}</p>
                                                {rowListItems3.map((item3) => {
                                                    return (
                                                        <p className='li-item'>{item3}</p>
                                                    )
                                                })}
                                            </div>
                                            <div className='col-lg-6 col-md-12 mb-3 mb-lg-0'>
                                                <p className='row-list-item-heading'>{rowListItemHeading4}</p>
                                                {rowListItems4.map((item4) => {
                                                    return (
                                                        <p className='li-item'>{item4}</p>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                    <p className='mb-3 mb-lg-3 col-12-text-center'>
                                        {DashedPara1 && (<p><span className='bullet-point'></span> {DashedPara1}</p>)}
                                    </p>
                                    <p className='mb-3 mb-lg-3 col-12-text-center'>
                                        {DashedPara2 && (<p><span className='bullet-point'></span> {DashedPara2}</p>)}
                                    </p>
                                    <p className='mb-3 mb-lg-3 col-12-text-center'>
                                        {DashedPara3 && (<p><span className='bullet-point'></span> {DashedPara3}</p>)}
                                    </p> 
                                    <p className='mb-2 mb-lg-3 col-12-text-center'>
                                        {breakParagraph2}
                                    </p>
                                    <div className='row mt-0 list-items-logo d-flex align-items-center'>
                                        <div className='col-lg-8 col-md-8'>
                                            <ul className='list-items mt-3'>
                                                { listItems && !strengthConditioningListItems ? listItems.map(element => {return (<li>{element}</li>)}) : null }
                                            </ul>
                                        </div>
                                        <div className='col-lg-4 col-md-4 m-0 p-0 '>
                                            { ExtraLogoBeforeBtn && <img src={ExtraLogoPath} className={`img-fluid`} style={dimensionsExtra} /> }
                                        </div>
                                    </div>  
                                    <div className='mt-3 mt-xl-4 col-12-text-center'>
                                        {buttonRoute && !removeBtn && !programsRoute && (
                                            <Link to={buttonRoute}>
                                                { btnReq && <button  onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn'>{buttonTitle} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button> }
                                            </Link>
                                        )}
                                        {!buttonRoute && !removeBtn && !programsRoute &&'/appointment-booking' &&  
                                            <div>
                                                { btnReq && <button onClick={DirectToEnquery} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn'>{buttonTitle} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button> }
                                            </div>
                                        }
                                        {programsRoute && removeBtn &&
                                            <Fragment>
                                                <a href={targetSection}>
                                                    <button onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn'>{buttonTitle} <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>
                                                </a>
                                            </Fragment>
                                        }
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CutImageContent;