import React, {useState} from 'react';   
import { Link } from "react-router-dom";
import './GoogleReviews.css';
 

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Autoplay, Navigation, Pagination]); 

const GoogleReviews = ({text, loop, buttonRequired = false, backgroundImage, reviewsArr}) => { 
  const [hoveredCardId, setHoveredCardId] = useState(null);
  const [btnHover, setBtnHover] = useState(false)
  return (
    <div className="slider-wrapper-google-reviews" style={{backgroundImage: `url(${backgroundImage})`}}>
      <div className="container">
        <div className='clients_info text-center mb-5'>
          <h1>{text}</h1>
        </div>
        <div className='text-center'>
            <Swiper
                spaceBetween={30}
                slidesPerView={3}
                loop={loop}
                autoplay={{ delay: 1000 }}
                speed={500} 
                pagination={{ clickable: true }}
                breakpoints={{
                    280: {
                      slidesPerView: 1,
                    },
                    340: {
                      slidesPerView: 1,
                    },
                    440: {
                      slidesPerView: 1,
                    },
                    540: {
                      slidesPerView: 1,
                    },
                    600: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                }}
            >
              {reviewsArr.map((card) => (
                <SwiperSlide key={card.id}>
                  <div className={`card mb-5 p-3 ${card.id === hoveredCardId ? "hovered" : ""}`}
                    onMouseEnter={() => setHoveredCardId(card.id)}
                    onMouseLeave={() => setHoveredCardId(null)}
                  >
                    <div className='row text-center'>
                      <div className='col-12 pt-3'> 
                        <img src={card.picture} className='img-fluid picture' />
                        <h1 className='title'>{card.title}</h1> 
                        <img src={card.reviewsLogo} className='img-fluid' alt={card.title} />
                        <div className="line mb-3"></div>
                        <p>{card.comments}</p>
                        <p>{card.anotherPara}</p>
                        <p>{card.para3}</p>
                        <p>{card.para4}</p>
                        <p>{card.para5}</p>
                      </div> 
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {buttonRequired && 
              <Link to='/speed-training-program'>
                <button onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn mt-4'>Explore <span><img src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} className='ml-2 img-fluid' /></span></button>
              </Link>
            }
        </div>
      </div>
    </div>
  )
}

export default GoogleReviews;