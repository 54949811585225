import React from 'react'; 
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import './News.css';  
import { cards } from './NewsArticles';
import { Link } from 'react-router-dom';

SwiperCore.use([Pagination]);

const News = () => {
  return (
    <div className="slider-wrapper-news-articles">
      <div className="container">
        <div className='new_articles text-center mb-5'>
          <h1>ARTICLES & NEWS</h1> 
        </div>
        <div>
            <Swiper
                spaceBetween={30}
                slidesPerView={3}
                loop={false}
                navigation={false}
                breakpoints={{
                    280: {
                      slidesPerView: 1,
                    },
                    340: {
                      slidesPerView: 1,
                    },
                    440: {
                      slidesPerView: 1,
                    },
                    540: {
                      slidesPerView: 1,
                    },
                    600: {
                      slidesPerView: 1,
                    },
                    640: {
                      slidesPerView: 2,
                    },
                    768: {
                      slidesPerView: 3,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                }}
            >
                {cards.map((card) => (
                  <SwiperSlide key={card.id}>
                    <Link to={`/blogs/${card.str_id}`} className='link-tag-card'>
                      <div className="card card-default-height mb-5" style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='row text-start'>
                          <div className='col-12'>
                            <img src={card.image} className='img-fluid thumbnail-icon mb-3' alt={card.title} />
                          </div>
                          <div className='col-12' style={{margin: 'auto 0 0 0'}}>
                            <div className='article_short_info'>
                              <h1>{card.heading}</h1> 
                              <p>{card.paragraph}</p>
                            </div>
                            <div className='timing_info' style={{marginTop: 'auto'}}>
                              <div class="line mb-3"></div>
                              <p>{card.dates} <span className='ms-3'>  {card.time}</span></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
        </div>
        <div className='blogs-cta text-center mb-5'>
          <Link to='/blogs'>
            <button className='skew-btn'>Learn More</button>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default News;