import React from 'react'; 
import './polyfills';
import App from './App';
import ReactDOM from 'react-dom/client'; 
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'; 
import 'swiper/swiper-bundle.min.css';
import './index.css'; 
import { Provider } from 'react-redux';
import store from "./store/store";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
); 
reportWebVitals();