import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import RegularHero from '../components/Hero-Section/RegularHero';

const UserDeletion = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const UserDeletionModule = () => {
        return( 
            <div className='user_deletion_screen'>
                <RegularHero  
                    startHighlightedText={true}
                    highlightedText={'USER DATA'}
                    breakLine={true} 
                    text={'DELETION POLICY'}  
                />
                <div className='content_usr_deletion container'> 
                    <p className='mt-3'>If you decide to remove the integration of your Instagram feed from our website, we provide the following options for deleting your user data.</p>

                    <h3>Deletion Process</h3>
                    <p>Once your identity is verified, we will immediately proceed with the deletion of your user data associated with the Instagram feed integration. This includes your Instagram username, profile picture, and public posts.</p>

                    <h3>Confirmation of Deletion</h3>
                    <p>After completing the data deletion process, we will send you a confirmation that your user data has been successfully deleted. Please note that it may take a short period of time for the deletion to be fully reflected in our systems and backups.</p>

                    <h3>Backup Copies</h3>
                    <p>We strive to delete your user data from all active systems and databases. It's important to note that no data backups may exist for recovery purposes.</p>

                    <h3>Security</h3>
                    <p>We take reasonable measures to protect the information collected from your Instagram account against unauthorized access, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h3>Retention of Other Data</h3> 
                    <p>It's important to note that the data deletion request only applies to the information collected from your Instagram account for the purpose of the feed integration. Any other data you have provided to us or that we have collected separately will be subject to our general data retention policies as outlined in our Privacy Policy.</p>
 
                    <p>Please note that by integrating Instagram feeds into our website, you acknowledge that the display of your Instagram feed will be public and visible to visitors of our website. It is your responsibility to ensure that the content shared through your Instagram account is appropriate and complies with Instagram's terms of service and privacy policy.</p>
 
                    <p className='mb-3'>If you have any questions or concerns about our Privacy Policy or User Data Deletion Policy, please contact our support team for further assistance.</p>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {loading ? <Loader /> : UserDeletionModule()}
        </Fragment> 
    )
}

export default UserDeletion;