import React from 'react'

const Loader = () => {
  return (
    <div className="loader">
      <div className="spinner"></div>
      <div className="loader-icon" style={{ background: `url(/images/icons/olympian.png) center center no-repeat`, backgroundSize: 'cover'}}></div>
    </div>
  )
}

export default Loader;