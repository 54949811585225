import React from 'react';
import Popup from '../Popup/Popup';
import $ from 'jquery';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Navigation, Pagination]);

const PopupGallery = ({setClosePopup, img, imagesArr}) => {
  const PopupCloseHandler = () => {
    setClosePopup(false)
    $('html').removeClass('rs_popup_open');
  }

  return (
    <Popup className='popup-img' autoOpen={true} width="auto" onClose={PopupCloseHandler}>
      <div className='container-fluid'>
        <Swiper slidesPerView={1} navigation={true}>
          <SwiperSlide>
            <img src={img} className='img-fluid ps-0 p-0 m-0' />
          </SwiperSlide>
          {imagesArr.map((item) => (
            <SwiperSlide key={item.id}>
              <img src={item.img} className='img-fluid ps-0 p-0 m-0' />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Popup>
  )
}

export default PopupGallery;