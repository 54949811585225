export const ElementaryMembershipsTrackFieldArr = [
    {
      id: 1,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY TRACK',
      subTitle: 'SPRING MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/a9fcc7c7-aa39-4c3c-938d-4dd8c7b96c91'
    },
    {
      id: 2,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY TRACK',
      subTitle: 'SUMMER MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/49fea2cb-e54c-4c54-8540-932d2fa4655d'
    },
    {
      id: 3,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY TRACK',
      subTitle: 'FALL MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/7690c378-b416-4a9b-ae10-0901aef4faec'
    },
    {
      id: 4,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY TRACK',
      subTitle: 'WINTER MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/3a40dbb8-e22b-4fd6-830f-6242824fade8'
    },
    {
      id: 5,
      icon: '/images/icons/shoe.svg',
      title: 'COMBINED EVENTS',
      subTitle: 'Elementary Track',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/3ea2004f-28b4-45cf-9577-643a1828dbed'
    }, 
    {
      id: 7,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY TRACK',
      subTitle: 'CROSS COUNTRY',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/e7bde227-d53c-4e61-96f9-ce6a68abc5c3?fbclid=IwAR0e4Si1IYfiHLzQLsnc6KDg-RwS3bEaWkIXzgBrXzN5OEIaH36CTyhSv5M'
    },
    {
      id: 8,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY JAVELIN',
      subTitle: 'Single Event',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/e4eedc52-b6ec-4196-9171-a9e548dfe1fc'
    },
    {
      id: 9,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY LONG JUMP',
      subTitle: 'Single Event',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/feb22cc4-67d9-481b-8091-d17bcaaa3872'
    },
    {
      id: 10,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY HIGH JUMP',
      subTitle: 'Single Event',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/58c044b0-1477-4b3e-9628-c9381ef2545b'
    },
    {
      id: 11,
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY HURDLES',
      subTitle: 'Single Event',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/f8fbf0dc-35f7-4104-85e3-8e06b2e063a1'
    },
    {
      id: 12,
      icon: '/images/icons/shoe.svg',
      icon: '/images/icons/shoe.svg',
      title: 'ELEMENTARY POLE VAULT',
      subTitle: 'Single Event',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/a295115f-a628-43ec-beb9-332ead16457b'
    },
    {
        id: 13,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL TRACK',
        subTitle: 'SPRING MEMBERSHIP',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/475f73db-5759-49ce-93a7-90e0705ae659'
    },
    {
        id: 14,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL TRACK',
        subTitle: 'SUMMER MEMBERSHIP',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/b37470cd-5718-44ed-9400-b1a3483d798f'
    },
    {
        id: 15,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL TRACK',
        subTitle: 'FALL MEMBERSHIP',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/4a7c68f5-cda5-4f32-8814-066939aa447d'
    },
    {
        id: 16,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL TRACK',
        subTitle: 'WINTER MEMBERSHIP',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/23f6f695-a66e-4371-affb-d96b84b04886'
    },
    {
        id: 17,
        icon: '/images/icons/shoe.svg',
        title: 'COMBINED EVENTS',
        subTitle: 'Highschool Track',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/de020be4-2164-4fe2-9375-bc0bfda2e40a'
    }, 
    {
        id: 19,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL TRACK',
        subTitle: 'CROSS COUNTRY',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/7aeba977-c65f-460a-a3cf-127fc4324b94'
    },
    {
        id: 20,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL JAVELIN',
        subTitle: 'Single Event',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/5500434d-8e08-48e8-8160-445242a47cd6'
    },
    {
        id: 21,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL LONG JUMP',
        subTitle: 'Single Event',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/eca53234-4f0a-4aaf-af9f-fb1d9d987e63'
    },
    {
        id: 22,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL HIGH JUMP',
        subTitle: 'Single Event',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/89598029-05fa-4283-b927-310ca4065023'
    },
    {
        id: 23,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL HURDLES',
        subTitle: 'Single Event',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/ad2111f9-838e-40a3-8e3f-c1f257007e0b'
    },
    {
        id: 24,
        icon: '/images/icons/shoe.svg',
        title: 'HIGH SCHOOL POLE VAULT',
        subTitle: 'Single Event',
        registrationLink: 'https://forms.360player.com/questsportscanada/form/0cab1b44-2d08-44f6-8ed1-4b625d9877cc'
    }
];

export const SpeedTrainingMembershipsArr =  [
    {
      id: 1,
      title: '360 ATHLETIC',
      icon: '/images/icons/running.svg',
      subTitle: 'Speed, Strength & Sport Specific Skills',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/71791b30-69b4-4638-b416-a8137b439699'
    },
    {
      id: 2,
      title: 'SPEED TRAINING',
      icon: '/images/icons/running.svg',
      subTitle: 'BRONZE MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/26afa2ff-7f54-4f34-a2da-081924e785ce'
    },
    {
      id: 3,
      title: 'SPEED TRAINING',
      icon: '/images/icons/running.svg',
      subTitle: 'SILVER MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/b9ca3bb3-65de-465a-9f4b-59f5a2204170'
    },
    {
      id: 4,
      title: 'SPEED TRAINING',
      icon: '/images/icons/running.svg',
      subTitle: 'GOLD MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/2685529a-1298-4add-b2d3-5049a0e48416'
    }
]

export const StrengthConditioningMembershipsArr = [
    {
      id: 1,
      icon: '/images/icons/dumble.svg',
      title: 'AFTER SCHOOL FITNESS PROGRAM',
      subTitle: 'BEGINNER',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/64c526d9-05ac-4dfa-881a-61520efadf35'
    },
    {
      id: 2,
      icon: '/images/icons/dumble.svg',
      title: 'AFTER SCHOOL FITNESS PROGRAM',
      subTitle: 'INTERMEDIATE',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/000d71b9-c72a-4f9e-8a1c-432152b0b764'
    },
    {
      id: 3,
      icon: '/images/icons/dumble.svg',
      title: 'AFTER SCHOOL FITNESS PROGRAM',
      subTitle: 'ADVANCE',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/af1ff261-8f4c-48d4-9d3e-bebb26a627a3'
    },
    {
      id: 4,
      icon: '/images/icons/dumble.svg',
      title: 'ADVANCE STRENGTH & CONDITIONING',
      subTitle: 'BRONZE MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/7a65a2b5-22c3-4230-bb4a-0b00fa1855a8'
    },
    {
      id: 5,
      icon: '/images/icons/dumble.svg',
      title: 'ADVANCE STRENGTH & CONDITIONING',
      subTitle: 'SILVER MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/ec1539e4-9082-4a0d-a7b6-f0470f91bd30'
    },
    {
      id: 6,
      icon: '/images/icons/dumble.svg',
      title: 'ADVANCE STRENGTH & CONDITIONING',
      subTitle: 'GOLD MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/bd6f85bb-a8bb-442c-9105-c8dc8c97ed4c'
    },
    {
      id: 7,
      icon: '/images/icons/dumble.svg',
      title: 'TEAM STRENGTH & CONDITIONING',
      subTitle: 'TEAM MEMBERSHIP',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/04029cd9-4f6d-4fb3-8e3b-b3229ed8cfed'
    },
    {
      id: 8,
      title: '360 ATHLETIC',
      icon: '/images/icons/running.svg',
      subTitle: 'Speed, Strength & Sport Specific Skills',
      registrationLink: 'https://forms.360player.com/questsportscanada/form/71791b30-69b4-4638-b416-a8137b439699'
    },
];
 