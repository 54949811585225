import React, { Fragment, useEffect, useState } from 'react';
import Loader from '../components/Loader/Loader';
import RegularHero from '../components/Hero-Section/RegularHero';

const PrivacyPolicy = () => {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 500);
    }, []);

    const PrivacyPolicyModule = () => {
        return( 
            <div className='privacy_policy_screen'>
                <RegularHero  
                    startHighlightedText={true}
                    highlightedText={'PRIVACY'}
                    breakLine={true} 
                    text={'POLICY'}  
                />
                <div className='content_prvcy container'> 
                    <p className='mt-3'>This Privacy Policy explains how we collect, use, and disclose information when you integrate Instagram feeds into our website. By using our website and integrating Instagram feeds, you consent to the practices described in this policy.</p>

                    <h3>Information Collection</h3>
                    <p>Integration: When you integrate Instagram feeds into our website, we collect certain information from your Instagram account, including your Instagram username, profile picture, and public posts. This information is necessary to display your Instagram feed on our website.</p>

                    <h3>Use of Information</h3>
                    <p>The information collected from your Instagram account is used solely for the purpose of displaying your Instagram feed on our website. We do not use this information for any other purpose or share it with any third parties.</p>

                    <h3>Data Retention</h3>
                    <p>We retain the information collected from your Instagram account as long as you continue to use our website and have your Instagram feeds integrated. If you decide to remove the Instagram feed integration, we will no longer collect or store any information from your Instagram account.</p>

                    <h3>Security</h3>
                    <p>We take reasonable measures to protect the information collected from your Instagram account against unauthorized access, alteration, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>

                    <h3>Third-Party Services</h3> 
                    <p>Our website may contain links to third-party websites or services. This Privacy Policy does not apply to those third-party websites or services. We encourage you to review the privacy policies of those third parties before interacting with them.</p>

                    <h3>Children's Privacy</h3>
                    <p>Our website and services are not intended for individuals under the age of 13. We do not knowingly collect or solicit personal information from anyone under the age of 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will delete such information from our systems.</p>

                    <h3>Changes to the Privacy Policy</h3>
                    <p className='mb-3'>We may update this Privacy Policy from time to time. Any changes will be effective immediately upon posting the updated Privacy Policy on our website. It is your responsibility to review this Privacy Policy periodically for any changes.</p>
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            {loading ? <Loader /> : PrivacyPolicyModule()}
        </Fragment> 
    )
}

export default PrivacyPolicy;
