import React from 'react';
import './CoachAssociations.css';

const CoachAssociations = ({heading, imgURL1, imgURL2}) => {
  return (
    <div className='coaching_associations_logos'>
        <div className='container-fluid'>
            <div className='row'>
                <div className='col-12 d-flex justify-content-center mb-5'>
                    <h2>{heading}</h2> 
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
                    <div className='row'>
                        <div className='col-lg-6 mb-3 justify-content-center align-items-center'>
                            <img src={imgURL1} className='img-fluid coach_logo' />
                        </div>
                        <div className='col-lg-6 mb-3 justify-content-center  align-items-center'>
                            <img src={imgURL2} className='img-fluid coach_logo' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CoachAssociations;