import React, {useState, useEffect, Fragment} from 'react'
import Associations from '../components/Associations Slider/Associations'; 
import CutImageContent from '../components/Cutted Image Content/CutImageContent';
import Hero from '../components/Hero-Section/Hero';
import Loader from '../components/Loader/Loader';
import News from '../components/News-Articles/News';
import RadialImgContent from '../components/Radial Image Content/RadialImgContent';  
import { Helmet } from 'react-helmet';

const Home = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const HomePageModule = () => {
    return (
      <Fragment>
        <Helmet>
          <title>Welcome to Quest Sports Track & Field Club</title>
          <meta name="keywords" content="Quest Sports Track & Field Club"/>
          <link rel="canonical" href="https://www.questsportscanada.club/" />
          <meta name="description" content="Quest sports academy in Ontario offers comprehensive training programs for athletes of all levels, from beginners to professionals Our experienced coaches and state of the art facilities will help you reach your full potential "/>
         
        </Helmet>
        <div className='quest_sp_home_page'>
          <Hero />
          <Associations />
          <CutImageContent 
            // highlightedParagraph={`WEIGHTS ARE SAFE & BENEFICIAL FOR KIDS-UNDER QUALIFIED SUPERVISION`}
            heading={`QUEST SPORTS`}
            paragraph={`Quest Sports Performance Group (QSPG) is one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations.`} 
            buttonTitle={`About Us`}
            buttonRoute='/about'
            imgUrl={`/images/creatives/quest_sports_home_section_1.png`}
            listItems={['Track & Field', 'Strength & Conditioning', 'The Speed Training Lab']}
          />
          <RadialImgContent 
            heading={`Join Quest Sports Track and Field Club and Experience Quality Athletic Development`}
            paragraph={`The Quest Professional Athletic Group founded Quest Sports Inc. The group is comprised of current and former professional athletes, to Olympians, 
            who unanimously believe that Canada has yet to tap into its true athletic talents. In an effort to introduce the Quest Training Systems to the world of 
            sport, Quest Sports Inc. has set forth a bold and passionate plan to identify sports talent in children from the playgrounds of Canada. 
            Our goal is to take them from their humble beginnings in the playground to the realization of their true athletic potential.`}
            buttonTitle={`Inquire Now`} 
            buttonRoute={'/about'}
            buttonIcon='/images/icons/golden-torch.svg'
            hoverBtnIcon='/images/icons/black-torch.svg'
            imgUrl={`/images/creatives/teenage-girl-professional-runner.png`}
          />
          <CutImageContent  
            heading={`TRACK & FIELD`}
            paragraph={`Quest Sports Track & Field Club is a hidden gem that has emerged as the go to club for development, as we have produced some of the best young talent in Ontario!`} 
            breakParagraph={`At Quest Sports we take commitment and development seriously as we mould, shape and nurture the Next Generation of Canadian Track  & Field talent`}
            buttonTitle={`Learn More`}
            buttonRoute={'/track-field-club'}
            reverse={`flex-row-reverse`}
            imgUrl={`/images/creatives/track_field_home_section_3.png`} 
          />
          <CutImageContent 
            // highlightedParagraph={`WEIGHTS ARE SAFE & BENEFICIAL FOR KIDS-UNDER QUALIFIED SUPERVISION `}
            heading={`KILLER SPEED`}
            paragraph={`Speed is a necessity and is a key factor if your child wants to stand out from the rest of the competition. Consistent work coupled with a great work ethic will change the game for athletes who wish to take their speed to the next level.`} 
            buttonTitle={`Learn More`}
            buttonRoute={'speed-training'}
            imgUrl={`/images/creatives/img-content-5.png`}
            listItems={['Speed Lab', 'Sprinting Technique', 'Acceleration Development', 'Quality Movement Patterns', 'Cutting Edge Technology']}
          />
          <CutImageContent 
            // highlightedParagraph={`WEIGHTS ARE SAFE & BENEFICIAL FOR KIDS-UNDER QUALIFIED SUPERVISION `}
            heading={`STRENGTH & CONDITIONING`}
            ExtraLogoBeforeBtn={true}
            ExtraLogoPath={'/images/icons/vps.png'}
            paragraph={`The Athletes Activation System from LPS Athletic, Powers Quest Strength & Conditioning Gym. An internationally recognized system called Athlete Activation System (AAS) trusted by top coaches worldwide. This system maximizes and taps into athletes’ potential by activating higher threshold motor units.`} 
            buttonTitle={`Learn More`}
            dimensionsExtra={{width: '120px', height: '120px', marginTop: '0'}}
            reverse={`flex-row-reverse`}
            buttonRoute={'/strength-conditioning-gym'}
            imgUrl={`/images/creatives/img-content-1.png`}
            listItems={['Stimulates Bone Density Growth', 'Strengthens Connective Tissue', 'Builds Confidence', 'Children Programs']}
          />
          <RadialImgContent 
            heading={`Quest Sports Performance Merchandise`}
            // anotherParagraph={`Weather an athlete, parent or a fan, wearing a shirt, hoodie with is a great way of promoting team spirit.`}
            paragraph={`Wearing Quest Sports apparel is a great way to show support for our dedicated athletes. Family and fans alike can promote team spirit by wearing  our branded hoodies and T-shirts.`}
            buttonTitle={`SHOP NOW`} 
            buttonRoute={'/'}
            hoverBtnIcon={`/images/icons/cart-black.png`}
            imgUrl={`/images/creatives/Shirt.png`}
            buttonIcon={`/images/icons/cart.svg`}
          />
          <News />
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {loading ? <Loader /> : HomePageModule()}
    </Fragment>
  )
}

export default Home;