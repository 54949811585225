import React, { Fragment } from 'react'
import RegularHero from '../components/Hero-Section/RegularHero'
import ApplicationForm from './Landing Pages/ApplicationForm';

const Application = () => {
  return (
    <Fragment>
        <RegularHero 
          backgroundImage= {'/images/creatives/speed-training-membership.png'} 
          startHighlightedText={true}
          highlightedText={'APPLICATION'}
          breakLine={true}  
        /> 
        <ApplicationForm />
    </Fragment>
  )
}

export default Application;