import React from 'react';
import './Factors.css';

const Factors = () => {
  return (
    <div className='important_factors_component'>
        <div className='container'>
            <div className='factors_info text-center'>
                <h1 className='mb-5 mt-5'> <span className='imp'>IMPORTANT </span> <span className='factors'>FACTORS</span></h1>  
            </div>

            <div className='row'>
                <div className='col-lg-6  mb-3'>
                    <div className='card rounded factors_content'>
                        <div className='card-body'>
                            <h5 className='card-title'><span className='mr-2'><img src='/images/icons/technique.png' className='img-fluid' alt='technique_img' /></span> TECHNIQUE</h5>
                            <p className='card-text'>Athletes who improve their technique for sprinting should see significant gains in their speed. Technical attributes includes acceleration phase, arm technique, joint flexibility, stride length and frequency.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6  mb-3'>
                    <div className='card rounded factors_content'>
                        <div className='card-body'>
                            <h5 className='card-title'><span className='mr-2'><img src='/images/icons/acceleration.png' className='img-fluid' alt='acceleration_img' /></span> ACCELERATION</h5>
                            <p className='card-text'>Acceleration is the rate of change in velocity, this phase of sprinting is critical for changing directions as rapidly and efficiently as possible.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6  mb-3'>
                    <div className='card rounded factors_content'>
                        <div className='card-body'>
                            <h5 className='card-title'><span className='mr-2'><img src='/images/icons/rate-of-force.png' className='img-fluid' alt='force_img' /></span> RATE OF FORCE</h5>
                            <p className='card-text'>The rate of force development measures how fast an athlete can develop force. Athletes with higher rates of force development have been shown to perform better during numerous physical performance tests.</p>
                        </div>
                    </div>
                </div>
                <div className='col-lg-6  mb-3'>
                    <div className='card rounded factors_content'>
                        <div className='card-body'>
                            <h5 className='card-title'><span className='mr-2'><img src='/images/icons/joint-flex.png' className='img-fluid' alt='joint_flex_img' /></span> JOINT FLEXIBILITY</h5>
                            <p className='card-text'>Proper flexibility of the joints contributes to more fluid and coordination, resulting in longer and faster strides and more incredible speed more fluid and coordinated efforts will result in longer and faster strides.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Factors;