import React, { Fragment } from 'react'
import AssociationSlider from './Landing Page Components/AssociationSlider';
import TeenageFlying from './Landing Page Components/TeenageFlying';
import SalesContactForm from './Landing Page Components/SalesContactForm';
import QuestAccomplishment from './Landing Page Components/QuestAccomplishment';
import Events from '../../components/Events/Events';
import PerformanceGroup from '../../components/Performance Group/PerformanceGroup';
import Team from '../../components/Our Team/Team';
import CutImageContent from '../../components/Cutted Image Content/CutImageContent';
import { OlympianTeam } from '../../components/Constants/OlympianTeam';
import { Helmet } from 'react-helmet';

const TrackFieldLandingPage = () => {
  return (
    <Fragment>
      <Helmet>
        <meta name="robots" content="noindex" /> 
      </Helmet>
      <SalesContactForm />  
      <AssociationSlider />
      <TeenageFlying />
      <Team array={OlympianTeam} loop={false} padding='0rem' />  
      <CutImageContent
        heading={`Running Events`}
        paragraph={`Quest Sports Canada’s Track and Field Club believes that speed is essential for all track and field events.`}  
        buttonTitle={`Become A Member`}
        buttonRoute={'/track-field-club'}
        removeBtn={true}
        programsRoute={true}
        landingComp={true}
        arboxText={'Track & Field'}
        rowItems={true}
        rowListItemHeading3={'DISTANCE'}
        rowListItemHeading1={'SPRINTS'}
        rowListItemHeading2={'HURDLES'}
        rowListItems3={['Middle & Long Distance']}
        rowListItems1={['100 meters','200 meters','400 meters' ]}
        rowListItems2={['60m to 100m (girls)', '60m to 110m (boys)', '200m to 400m long hurdles']}
        rowListItemHeading4={'RELAYS'}
        rowListItems4={['4 X 100 & 4 X 400']}
        reverse={`flex-row-reverse`} 
        imgUrl={`/images/creatives/square-img-1.png`} 
      />
      <CutImageContent
        heading={`SPEED DEVELOPMENT`}
        paragraph={`At Quest Sports, we are all about speed development`} 
        DashedPara1={`We believe consistent work will change the game for athletes who     wish to take their training to the next level.`}
        DashedPara2={`We know that accelerating quickly and powerfully is probably the most important skill that needs improvement in all athletes.`}
        DashedPara3={`We teach all the movement patterns to condition the neuromuscular system to improve the firing patterns of fast-twitch muscle fibres.`}
        buttonTitle={`Become A Member`}
        buttonRoute={'/track-field-club'}
        landingComp={true}
        removeBtn={true}
        programsRoute={true}
        arboxText={'Track & Field'}
        imgUrl={`/images/creatives/square-img-2.png`}
      />
      <CutImageContent
        heading={`HIGH JUMP`}
        paragraph={`High Jump Quest Sports Canada’s Track and Field Club focuses on two main jumping events: The long jump and the high jump. Conditioning programs are put in place to support these events.`} 
        //  breakParagraph={`Quest Sports Performance Group (QSPG) is one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations. We develop athletes from the ground up, covering all aspects of athletic development.`}
        buttonTitle={`Become A Member`} 
        buttonRoute={'/track-field-club'}
        reverse={'flex-row-reverse'}
        arboxText={'Track & Field'}
        landingComp={true}
        removeBtn={true}
        programsRoute={true}
        imgUrl={`/images/creatives/square-img-3.png`} 
      />
      <CutImageContent
        heading={`LONG JUMP`}
        paragraph={`Quest Sports Canada’s Track and Field Club focuses on two main jumping events: The long jump and the high jump. Conditioning programs are put in place to support these events.`} 
      //  breakParagraph={`Quest Sports Performance Group (QSPG) is one of Ontario's premier youth and junior development programs. We have designed a sports culture that supports athletes who wish to train and develop beyond their expectations. We develop athletes from the ground up, covering all aspects of athletic development.`}
        buttonTitle={`Become A Member`}
        removeBtn={true}
        programsRoute={true}
        arboxText={'Track & Field'}
        landingComp={true}
        buttonRoute={'/track-field-club'}
        imgUrl={`/images/creatives/square-img-4.png`} 
      />
      <CutImageContent
        heading={`THROW EVENTS`}
        paragraph={`Our club also focuses on the following throwing events: Javelin, Shot Put, & Discuss. A strength and conditioning program is put in place to support these events.`}  
        buttonTitle={`Become A Member`}
        buttonRoute={'/track-field-club'} 
        reverse={'flex-row-reverse'}
        landingComp={true}
        removeBtn={true}
        arboxText={'Track & Field'}
        programsRoute={true}
        imgUrl={`/images/creatives/square-img-5.png`} 
      />   
      <PerformanceGroup />
      <QuestAccomplishment />
      <Events />
    </Fragment>
  )
}

export default TrackFieldLandingPage;