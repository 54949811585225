import React, { Fragment } from 'react'
import ElementaryMembership from '../components/Registrations/ElementaryMembership'
import RegularHero from '../components/Hero-Section/RegularHero' 
import { ElementaryMembershipArray, HighSchoolMembershipArray, SpeedTrainingMembershipArray, StrengthConditioningMembershipArray } from '../components/Constants/MemberShipsArray'

const Register = () => {
  return (
    <Fragment>
      <RegularHero 
        backgroundImage= {'/images/creatives/speed-training-membership.png'} 
        startHighlightedText={true}
        highlightedText={'REGISTRATION'}
        breakLine={true}  
      />
      <ElementaryMembership 
        membershipArray={ElementaryMembershipArray}
        mainText={`SIGNUP FOR ELEMENTARY MEMBERSHIP`} 
      />
      <ElementaryMembership 
        membershipArray={HighSchoolMembershipArray}
        mainText={`SIGNUP FOR HIGHSCHOOL MEMBERSHIP`} 
      />
      <ElementaryMembership 
        membershipArray={SpeedTrainingMembershipArray}
        mainText={`SIGNUP FOR SPEED TRAINING MEMBERSHIP`} 
      />
      <ElementaryMembership 
        membershipArray={StrengthConditioningMembershipArray}
        mainText={`SIGNUP FOR STRENGTH CONDITIONING MEMBERSHIP`} 
      />
    </Fragment>
  );
};

export default Register;