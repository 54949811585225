import React from 'react';
import './WeightLiftingAthlete.css';

const WeightLiftingAthlete = () => {
  return (
    <div className='weightlifting_benefits_athletes d-flex justify-content-center'>
        <div className='container'>
            <div className='weightlifting_young_athletes text-center'>
                <h1 className='mb-5'>WEIGHTLIFTING BENEFITS FOR YOUNG ATHLETES</h1>
            </div>
            <div className='weighting_row_wrapper'>
                <div className='row'>
                    <div className='col-lg-4 col-md-12 col-12'>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Increases Power
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Improves Agility
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Increases Explosiveness
                        </h4>
                    </div>
                    <div className='col-lg-4 col-md-12 col-12'>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Become Stronger
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Become Faster
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Reduces The Risk of Injury
                        </h4>
                    </div>
                    <div className='col-lg-4 col-md-12 col-12'>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Strengthens Bones
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Improves Confidence
                        </h4>
                        <h4>
                            <span className='check-icon mr-3'><img src='/images/icons/checkmark.svg' className='img-fluid' /></span>
                            Promotes a Healthy Weight
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
export default WeightLiftingAthlete;