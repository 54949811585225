export const AccomplishmentsArray = [
    { id: 1, logo: '/images/icons/trophy.svg', year: '2016', subtitle: `ONTARIO INDOOR PROVINCIAL CHAMPIONSHIPS (MTA) U14, U16, U20 Champs`, listItems: [
        {
            childArray: [
                'TYKE Girls 100m',
                'Silver - Shadae Thompson'
            ]
        },
        {
            childArray: [
                'ATOM GIRLS 200m Hurdles',
                'SILVER Elle Riley'
            ]
        },
        {
            childArray: [
                'ATOM GIRLS High Jump',
                'SILVER - Stephanie Dundas'
            ]
        }
    ]},

    { id: 2, logo: '/images/icons/trophy.svg', year: '2017', subtitle: 'ONTARIO OUTDOOR PROVINCIAL CHAMPIONSHIPS (MTA)', listItems:[
        {
            childArray: [
                'SENIOR GIRLS 200m Hurdles',
                'BRONZE Samantha Bartlette'
            ]
        },
        {
            childArray: [
                'SENIOR GIRLS Long Jump',
                'SILVER Maiya Grubin'
            ]
        },
        {
            childArray: [
                'TYKE GIRLS 100m',
                'SILVER Shadae Thompson'
            ]
        },
    ]}, 
 
    { id: 3, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'ONTARIO INDOOR PROVINCIAL CHAMPIONSHIPS (MTA)', listItems: [
        {
            childArray: [
                'ATOM GIRLS 60m',
                'GOLD-Shadae Thompson'
            ]
        },
        {
            childArray: [
                'ATOM - GIRLS 300m',
                'GOLD-Shadae Thompson'
            ]
        },
        {
            childArray: [
                'BANTOM GIRLS 60m',
                'GOLD Robyn Larkan'
            ]
        },
        {
            childArray: [
                'BANTOM GIRLS 150m',
                'GOLD Elle Riley'
            ]
        },
        {
            childArray: [
                'BANTOM GIRLS 300m',
                'GOLD Elle Riley'
            ]
        },
        {
            childArray: [
                'BANTOM GIRLS 60m Hurdles',
                'GOLD Elle Riley',
                'BRONZE Robyn Larkan'
            ]
        },
        {
            childArray: [
                'BANTOM GIRLS High Jump',
                'GOLD Dani Allen',
                'BRONZE-Stephanie Dundas'
            ]
        },
        {
            childArray: [
                'MIDGET GIRLS 60m',
                'BRONZE Samantah Bartlett'
            ]
        },
        {
            childArray: [
                'MIDGET GIRLS 200m',
                'BRONZE-Samatha Bartlett'
            ]
        },
        {
            childArray: [
                'MIDGET GIRLS Long Jump',
                'SILVER-Maiya Grubin'
            ]
        },
        {
            childArray: [
                'YOUTH GIRLS Long Jump',
                'BRONZE-Jaden Gilbert'
            ]
        },
        {
            childArray: [
                'TYKE BOYS 150m',
                'SILVER-Braeden Hou'
            ]
        },
        {
            childArray: [
                'TYKE BOYS 300m',
                'SILVER-Braeden Hou'
            ]
        },
    ]},
 
    { id: 4, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'ONTARIO OUTDOOR PROVINCIAL Championships (MTA)', listItems: [
        {
            childArray: [
                'SENIOR GIRLS 400m',
                'GOLD-Elle Riley'
            ]
        },
        {
            childArray: [
                'SENIOR-G GIRLS 200 Meter Hurdles',
                'GOLD-Elle Riley'
            ]
        },
        {
            childArray: [
                'SENIOR GIRLS High Jump',
                'GOLD Hailey Usher'
            ]
        },
        {
            childArray: [
                'SENIOR GIRLS Long Jump',
                'GOLD Maiya Gurbin'
            ]
        },
        {
            childArray: [
                'SENIOR GIRLS Shot Put',
                'SILVER Maiya Gurbin'
            ]
        },
        {
            childArray: [
                'ATOM BOYS 200 Meter Hurdles 60cm',
                'BRONZE Josh Streeter'
            ]
        },
        {
            childArray: [
                'ATOM BOYS Long Jump',
                'BRONZE Josh Streeter'
            ]
        },
        {
            childArray: [
                'SENIOR BOYS 200 Meter Hurdles',
                'BRONZE Ethan Girard'
            ]
        },
    ]},
     
    { id: 5, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'OFSAA-HIGH SCHOOL CHAMPIONSHIPS', listItems: [
        {
            childArray: [
                'MIDGET BOYS Hurdles',
                'SILVER Robert Stan'
            ]
        },
        {
            childArray: [
                'JUNIOR BOYS Javelin',
                'SILVER REUEL'
            ]
        },
        {
            childArray: [
                'JUNIOR BOYS High Jump',
                'BRONZE -Ethan Ford'
            ]
        },
        {
            childArray: [
                'JUNIOR BOYS 300 m hurdles',
                '4th Ethan Ford'
            ]
        },
        {
            childArray: [
                'JUNIOR GIRLS triple Jump',
                '4th Dylana'
            ]
        },
        {
            childArray: [
                'JUNIOR GIRLS 100 m',
                '5th -Dylana'
            ]
        },

    ]},

    { id: 6, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'ONTARIO SUMMER GAMES', listItems: [
        {
            childArray: [
                'U18 Mens High Jump',
                'GOLD ETHAN FORD'
            ]
        },
        {
            childArray: [
                'Other Participants',
                'Maiya Grubin',
                'Samantha Bartlett'
            ]
        }
    ]},
   
    { id: 7, logo: '/images/icons/trophy.svg', year: '2019', subtitle: 'ONTARIO PROVINCIAL INDOOR CHAMPIONSHIPS (MTA)', listItems:[    
        {        
            childArray: [            
                'TYKE Girls 60 Meter Hurdles',            
                'SILVER - Camryn Larkan',            
                'BRONZE - E Brown'        
            ]
        },
        {
            childArray: [
                'ATOM Girls 60m',
                'SILVER - Shadae Thompson'
            ]
        },
        {
            childArray: [
                'ATOM Girls 200m',
                'GOLD - Shadae Thompson'
            ]
        },
        {
            childArray: [
                'SENIOR Girls 400m',
                'SILVER - Elle Riley'
            ]
        },
        {
            childArray: [
                'SENIOR Girls 60m Hurdles 68cm',
                'GOLD - Elle Riley'
            ]
        },
        {
            childArray: [
                'SENIOR Girls 4x200m Relay',
                'SILVER - Shadae Thompson, Robyn Larken, Stephanie Dundas, Elle Riley'
            ]
        },
        {
            childArray: [
                'SENIOR Girls Senior High Jump',
                'GOLD - Dani Allen'
            ]
        }
    ]},
 
    { id: 8, logo: '/images/icons/trophy.svg', year: '2019', subtitle: 'ONTARIO OUTDOOR CHAMPIONSHIPS (MTA)', listItems: [    
        {    
            childArray: [            
                'ATOM GIRLS 100m',            
                'SILVER- Shadae Thompson',            
                'BRONZE-Kayla Gaynor'        
            ]
        },
        {
            childArray: [
                'ATOM GIRLS 200m',
                'GOLD Shadae Thompson'
            ]
        },
        {
            childArray: [
                'ATOM GIRLS 200m Hurdles',
                'SILVER Mayane Fernandez'
            ]
        },
        {
            childArray: [
                'ATOM GIRLS 4x100m Relay',
                'GOLD & ONTARIO RECORD',
                'Myanne Fernandes, Kayla Gaynor, Bryana Gaynor, Shadae Thompson'
            ]
        },
        {
            childArray: [
                'ATOM Girls 200m Hurdles',
                'SILVER Myanne Fernandes'
            ]
        }
    ]},
  
     
    { id: 9, logo: '/images/icons/trophy.svg', year: '2020', subtitle: 'ONTARIO U14, U16, & U20 INDOOR CHAMPIONSHIPS(AO)', listItems: [
        {
        childArray: [
        "U14 GIRLS 60m",
        "GOLD Kayla Gaynor",
        "SILVER Shadae Thompson",
        "BRONZE Bryana Gaynor"
        ]
        },
        {
        childArray: [
        "U14 GIRLS 150m",
        "GOLD Kayla Gaynor",
        "SILVER Bryana Gaynor"
        ]
        },
        {
        childArray: [
        "U14 GIRLS 300m",
        "GOLD Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U16 GIRLS 60m Hurdles",
        "GOLD Robyn Larkan",
        "BRONZE-Stephanie Dundas"
        ]
        },
        {
        childArray: [
        "U14 BOYS High Jump",
        "SILVER-Noah Dyke"
        ]
        },
        {
        childArray: [
        "U16 BOYS Pole Vault",
        "SILVER-Jackson King"
        ]
        },
        {
        childArray: [
        "U16 GIRLS Long Jump",
        "SILVER-Stephanie Dundas"
        ]
        }
        ]
    },
 

    { id: 10, logo: '/images/icons/trophy.svg', year: '2021', subtitle: 'U16 ATHLETICS ONTARIO OUTDOOR PROVINCIAL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U16 GIRLS 100m",
        "GOLD -Bryana Gaynor",
        "SILVER -Jaeden Garroway",
        "BRONZE-Kayla Gaynor"
        ]
        },
        {
        childArray: [
        "U16 GIRLS 200m",
        "BRONZE- Kayla Gaynor"
        ]
        },
        {
        childArray: [
        "U16 300 m",
        "SILVER Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U16 200m Hurdles",
        "GOLD Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U16 4 X100m Relay",
        "GOLD Shadae Thompson, Kayla Gaynor, Brianna Gaynor, Jaeden Garroway"
        ]
        },
        {
        childArray: [
        "U18 COMBINED EVENTS",
        "GOLD- Dani Allen"
        ]
        }
        ]},
     
    { id: 11, logo: '/images/icons/trophy.svg', year: '2021', subtitle: 'ONTARIO PROVINCIAL CHAMPIONSHIPS(AO)', listItems: [
        {
        childArray: [
        "PEEWEE GIRLS 100m",
        "GOLD Avery Azeez"
        ]
        },
        {
        childArray: [
        "TYKE Girls 100m",
        "GOLD Na’Zia Winter"
        ]
        },
        {
        childArray: [
        "ATOM Girls 100m",
        "GOLD Alexia Jones",
        "SILVER Cami Larkan"
        ]
        },
        {
        childArray: [
        "MITE BOYS 100m",
        "SILVER Tre Paezer",
        "BRONZE KayJay Rodney"
        ]
        },
        {
        childArray: [
        "MITE GIRLS 200m",
        "SILVER Nyelah Wynter"
        ]
        },
        {
        childArray: [
        "MITE BOYS 200m",
        "SILVER KayJay Rodney"
        ]
        },
        {
        childArray: [
        "MITE GIRLS 400m",
        "GOLD Nyelah Wynter"
        ]
        },
        {
        childArray: [
        "ATOM GIRLS 400m",
        "SILVER Elise Martin"
        ]
        },
        {
        childArray: [
        "TYKE BOYS 400m",
        "GOLD Ellis Danquah"
        ]
        },
        {
        childArray: [
        "MITE GILRLS 60m Hurdles",
        "SILVER Charlize Van Langenberg"
        ]
        },
        {
        childArray: [
        "TYKE GILRLS 60m Hurdles",
        "BRONZE Maev Van Langenberg"
        ]
        },
        {
        childArray: [
        "MITE BOYS 60m Hurdles",
        "GOLD Tre Paezer"
        ]
        },
        {
        childArray: [
        "TYKE BOYS 60m Hurdles",
        "GOLD JR Paezer",
        "SILVER Ellis Danquah"
        ]
        },
        {
        childArray: [
        "ATOM GIRLS 200m Hurdles",
        "GOLD Alexia Jones Atom",
        "SILVER Cami Larkan"
        ]
        },
        {
        childArray: [
        "SENIOR GIRLS 200m Hurdles",
        "GOLD Gabrielle Geddes"
        ]
        }
        ]},
 
    { id: 11, logo: '/images/icons/trophy.svg', year: '2021', subtitle: 'NXTCHAMP Invitational', listItems: [
        {
        childArray: [
        "NOVICE GIRLS 100m",
        "SILVER- Jaeden Garroway"
        ]
        },
        {
        childArray: [
        "JUNIOR GIRLS 100m",
        "SILVER Robyn Larkan"
        ]
        },
        {
        childArray: [
        "JUNIOR BOYS 100m",
        "SILVER AJ Holmes"
        ]
        },
        {
        childArray: [
        "JUNIOR GIRLS 200m",
        "BRONZE-Robyn Larkan"
        ]
        },
        {
        childArray: [
        "NOVICE BOYS 400m",
        "BRONZE-Isaiah Brown"
        ]
        },
        {
        childArray: [
        "NOVICE GIRLS 80m Hurdles",
        "GOLD-Jaeden Garroway"
        ]
        },
        {
        childArray: [
        "JUNIOR Women 80m Hurdles",
        "GOLD -Elle Riley"
        ]
        },
        {
        childArray: [
        "SENIOR Women 100 Meter Hurdles",
        "BRONZE -Robyn Larkan"
        ]
        },
        {
        childArray: [
        "JUNIOR Women 300 Meter Hurdles",
        "GOLD-Elle Riley"
        ]
        },
        {
        childArray: [
        "JUNIOR Women High Jump",
        "GOLD-Dani Allen"
        ]
        }
        ]
    },
     
    { id: 12, logo: '/images/icons/trophy.svg', year: '2022', subtitle: 'ATHLETICS ONTARIO INDOOR U16 & U20 CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U16 GIRLS 200m",
        "SILVER Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U20 GIRLS 60m Hurdles",
        "GOLD Dani Allen"
        ]
        }
        ]
    },
      
    { id: 13, logo: '/images/icons/trophy.svg', year: '2022', subtitle: 'OFSAA-HIGH SCHOOL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "GRADE 9 100 meters",
        "GOLD Bryana Gaynor",
        "BRONZE Kayla Gaynor"
        ]
        },
        {
        childArray: [
        "GRADE 9 200m",
        "GOLD Shadae Thompson"
        ]
        },
        {
        childArray: [
        "GRADE 11 High jump",
        "BRONZE Dani Allen"
        ]
        }
        ]
    },
     
    { id: 14, logo: '/images/icons/trophy.svg', year: '2022', subtitle: 'ATHLETICS ONTARIO U14, U16, U18 OUTDOOR CHAMPIONSHIPS & U18 COMBINED EVENTS CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U14 GIRLS 80 m",
        "GOLD-Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 GIRLS 100m",
        "BRONZE- Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U14 GIRLS 150m",
        "GOLD- Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 GIRLS 200 Meter",
        "SILVER Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U14 GIRLS 80 Meter Hurdles",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 GIRLS 200m Hurdles",
        "GOLD Shadae Thompson"
        ]
        },
        {
        childArray: [
        "U16 Girls High Jump",
        "SILVER-Reign Wilson"
        ]
        },
        {
        childArray: [
        "U18 BOYS Decathlon",
        "SILVER-Jackson King"
        ]
        }
        ]
    },
 
    { id: 15, logo: '/images/icons/trophy.svg', year: '2023', subtitle: 'CANADIAN NATIONAL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U16 Canadian National Champion"
        ]
        },
        {
        childArray: [
        "U16 60m Hurdles",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 60 m",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 200m",
        "GOLD Alexia Jones"
        ]
        }
        ]
    },
 
    { id: 16, logo: '/images/icons/trophy.svg', year: '2023', subtitle: 'ATHLETICS ONTARIO U16 PROVINCIAL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "ALEXIA JONES U16 PROVINCIAL CHAMPION"
        ]
        },
        {
        childArray: [
        "U16 60m Hurdles",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 60 m",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 U16 200 m",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U16 Long Jump",
        "BRONZE Alexia Jones"
        ]
        },
        {
        childArray: [
        "GOLD U18 4x 200m Relay Team"
        ]
        },
        {
        childArray: [
        "U16 GIRLS Relay",
        "SILVER Savannah, Biananca , Arriana, Gabi"
        ]
        },
        {
        childArray: [
        "U16 Boys 300m",
        "SILVER Braeden Hou"
        ]
        }
        ]
    },
 
    { id: 17, logo: '/images/icons/trophy.svg', year: '2023', subtitle: 'ATHLETICS ONTARIO U18 PROVINCIAL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U18 60m",
        "GOLD Kayla Gaynor",
        "BRONZE Bryana Gaynor"
        ]
        },
        {
        childArray: [
        "U18 4 x 200m Relay Championship Team",
        "Shadae Thompson, Kayla Gaynor, Bryana Gaynor, Alexia Jones"
        ]
        },
        {
        childArray: [
        "U18 200m",
        "SILVER Kayla Gaynor"
        ]
        }
        ]
    },
 
    { id: 18, logo: '/images/icons/trophy.svg', year: '2023', subtitle: 'U20 PROVINCIAL CHAMPIONSHIPS', listItems: [
        {
        childArray: [
        "U120 Provincial Championships Multi-Events",
        "BRONZE-Jackson King"
        ]
        }
        ]
    },
 
    { id: 19, logo: '/images/icons/trophy.svg', year: '2019', subtitle: 'CANADIAN NATIONAL (QUEBEC VS ONTARIO)', listItems: [
        {
        childArray: [
        "GOLD 300m",
        "Elle Riley",
        "Stephanie Dundas",
        "Maiya Grubin",
        "Dani Allen"
        ]
        }
        ]
    },
 
   
    { id: 20, logo: '/images/icons/trophy.svg', year: '2020', subtitle: 'CANADIAN NATIONAL (QUEBEC VS ONTARIO)', listItems: [
        {
        childArray: [
        "GOLD 60m Hurdles",
        "Robyn Larkan",
        "Jackson",
        "Kayla",
        "Bryanna",
        "Dani"
        ]
        }
        ]
    },

    { id: 21, logo: '/images/icons/trophy.svg', year: '2023', subtitle: 'CANADIAN NATIONAL (QUEBEC VS ONTARIO)', listItems: [
        {
        childArray: [
        "GOLD 60m Hurdles",
        "Alexia Jones"
        ]
        },
        {
        childArray: [
        "GOLD High Jump",
        "Reign Wilson"
        ]
        }
        ]
    },
 
    { id: 22, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'USA COMPETITION (AMERICAN ATHLETIC UNION CHAMPIONSHIPS ALL AMERICAN)', listItems: [
        {
        childArray: [
        "U17 GIRLS High Jump",
        "GOLD - DANI ALLEN",
        "AAU All American"
        ]
        },
        {
        childArray: [
        "U18 BOYS High Jump",
        "SILVER - Ethan Ford",
        "AAU All American"
        ]
        },
        {
        childArray: [
        "AAU All Americans",
        "Elle Riley 400m",
        "Dani Allen High Jump",
        "Stephanie Dundas 200m",
        "Ethan Ford High Jump",
        "Shadae Thompson 60 m/ 200 m",
        "Bronze Medalist 4x 200 Relay"
        ]
        },
        ]
    },
 
    { id: 23, logo: '/images/icons/trophy.svg', year: '2018', subtitle: 'USA COMPETITION (New Balance Nationals-USA)', listItems: [
        {
        childArray: [
        "U15 60m",
        "GOLD Alexia Jones"
        ]
        },
        {
        childArray: [
        "U17 60 meters",
        "SEMI FINALIST Bryana Gaynor"
        ]
        }
        ]
    },
 
    { id: 24, logo: '/images/icons/trophy.svg', year: '2023 to date', subtitle: 'NOTABLE AWARDS & ACHIEVEMENTS (All Ontarioan)', listItems: [
        {  
            childArray: [
                'Elle Riley', 
                'Shadae Thompson', 
                'Alexia Jones',
            ]
        }
    ]},
  
    { id: 25, logo: '/images/icons/trophy.svg', year: '2023 to date', subtitle: 'NOTABLE AWARDS & ACHIEVEMENTS (MTA ATHLETE OF EXCELLENCE)', listItems: [
        {
            childArray: [
                'Robyn Larkan', 
                'Maiya Grubin', 
                'Stephanie Dundas', 
                'Shadae Thompson', 
                'Alexia Jones',
                'Elle Riley'
            ]
        } 
    ]},
  
    { id: 26, logo: '/images/icons/trophy.svg', year: '2023 to date', subtitle: 'NOTABLE AWARDS & ACHIEVEMENTS (ONTARIO RECORD HOLDERS)', listItems:[
        {
        childArray: [
        "U14 200m Hurdles",
        "28.86",
        "Elle Riley"
        ]
        },
        {
        childArray: [
        "U14 300m",
        "41.53",
        "Elle Riley"
        ]
        },
        {
        childArray: [
        "U14 60m",
        "7.65",
        "Alexia Jones"
        ]
        }
        ]
    },
   
    { id: 27, logo: '/images/icons/trophy.svg', year: '2023 to date', subtitle: 'NOTABLE AWARDS & ACHIEVEMENTS (CANADIAN RECORD HOLDERS)', listItems: [
        {
        childArray: [
        "U14 60m",
        "7.65",
        "Alexia Jones"
        ]
        }
        ]
    }, 
]
