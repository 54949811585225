import React, { Fragment } from 'react'
import RegularHero from '../components/Hero-Section/RegularHero';
import Associations from '../components/Associations Slider/Associations';
import ProgramsSchedulingWrapper from '../components/Programs Scheduling/ProgramsSchedulingWrapper';
import { ElementaryScheduleProgramsArray, HighSchoolScheduleProgramsArray, SCScheduleProgramsArray, ScheduleProgramsArray } from '../components/Constants/SchedulingPrograms';

const Schedule = () => {
  return (
    <Fragment> 
      <RegularHero
        backgroundImage= {'/images/creatives/schedule-hero.png'} 
        startHighlightedText={true}
        highlightedText={'QUEST SPORTS SCHEDULE'}
        breakLine={true}  
      />
      <Associations />
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/elementary_track_schedule.png'} slider_heading='ELEMENTARY TRACK SCHEDULE' slider_array={ElementaryScheduleProgramsArray} schedule_heading='' />
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/high_school_schedule.png'} slider_heading='HIGH SCHOOL TRACK SCHEDULE' slider_array={HighSchoolScheduleProgramsArray} schedule_heading='' />
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/s_c_schedule.png'} slider_heading='STRENGTH & CONDITIONING SCHEDULE' slider_array={SCScheduleProgramsArray} schedule_heading='' /> 
      <ProgramsSchedulingWrapper sectionBgImage={'/images/creatives/speed_training_schedule.png'} slider_heading='SPEED TRAINING SCHEDULE' slider_array={ScheduleProgramsArray} schedule_heading='' /> 
    </Fragment>
  )
}

export default Schedule;