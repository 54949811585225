import React from 'react';  
import './Associations.css';
import { slides } from './AssociationsSlides'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';

SwiperCore.use([Autoplay, Navigation, Pagination]);

/*
props:
Slides: array, []
  {
    id: int,
    image: ''
  }
*/

const Associations = ({translate = 'Y'}) => {
  return (
    <div className='associations_swiper_slider' style={{ transform: translate == 'Y' ? 'translateY(-37%)' : ( translate == 'X' ? 'translateY(20%)' : '' ) }}>
      <div className="container-fluid">
        <div className="row">
          <div className='col-12 d-flex justify-content-center mb-5'>
            <h2>ASSOCIATIONS</h2>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
            <Swiper
              className="mySwiper"
              spaceBetween={30}
              slidesPerView={1}
              slidesPerGroup={1}
              loop={true}
              autoplay={{ delay: 1000 }}
              speed={500} 
              breakpoints={{
                640: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                1024: {
                  slidesPerView: 4,
                },
              }}
            >
              {slides.map((slide) => (
                <SwiperSlide key={slide.id}>
                  <img src={slide.image} className={slide.className} alt={`Slide ${slide.id}`} />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
} 
export default Associations;