import React from 'react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { cards } from './TeamMembers';
import './Team.css';

SwiperCore.use([Pagination]);

const Team = ({array = cards, loop = true, padding = '7.4rem'}) => {
  return (
    <div className="slider-wrapper-team" style={{paddingBottom: padding}}>
      <div className="container">
        <div className='team_info text-center mb-5'>
          <h1>OUR TEAM</h1> 
        </div>
        <div>
          <Swiper
            spaceBetween={30}
            slidesPerView={3}
            loop={loop}
            navigation={false}
            pagination={{ clickable: true }}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              340: {
                slidesPerView: 1,
              },
              440: {
                slidesPerView: 1,
              },
              540: {
                slidesPerView: 1,
              },
              600: {
                slidesPerView: 1,
              },
              640: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {array.map((card) => (
              <SwiperSlide key={card.id}>
                <div className="card mb-5">
                  <div className='row text-center'>
                    <div className='col-12'>
                      <img src={card.image} className='img-fluid team-member' alt={'team_member'} />
                      <h1>{card.name}</h1>
                      {card.titles.map((item) => {return (<p>{item}</p>)})}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  )
}

export default Team;