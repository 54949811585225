import React, { useState } from 'react'
import CountryPicker from './CountryPicker';
import { Settings } from '../../inc/Settings';

const ApplicationForm = () => {
    const [formData, setFormData] = useState({
        qsc_yes: false,
        qsc_no: false,
        full_name: '',
        member: false,
        guardian: false,
        age: '',
        sport_priority_1: '',
        sport_priority_2: '',
        sport_priority_3: '',
        positions: '',
        country: '',
        state: '',
        major_obstacles: '',
        physical_performance: '',
        one_year_celebration: '',
        about_us: '',
        commercial_gym: false,
        group_classes: false,
        online_coaching: false,
        semi_private: false,
        pricate_one: false,
        other: false,
        strength_conditioning: false,
        track_field: false,
        speed_training: false,
        current_occupation: '',
        invest_financially: false,
        resourfull: false,
        cant_invest: false,
        email: '',
        phone_number: '',
    });
    const [btnHover, setBtnHover] = useState(false);
  
    let contactAPIFired = false;

    const onInputChangeHandler = (e) => {
        const {name, value, checked} = e.target;

        if(e.target.value){
            setFormData((prevState) => {
                return {
                  ...prevState,
                  [name]: value
                }
            })
        }
        if(e.target.checked){
            setFormData((prevState) => {
                return {
                  ...prevState,
                  [name]: checked
                }
            })
        }
        if (name === 'qsc_yes' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    qsc_yes: true,
                    qsc_no: false,
                }
            });
        } else if (name === 'qsc_no' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    qsc_yes: false,
                    qsc_no: true,
                }
            });
        }
        if (name === 'member' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    member: true,
                    guardian: false,
                }
            });
        } else if (name === 'guardian' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    member: false,
                    guardian: true,
                }
            });
        }
        if (name === 'invest_financially' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    invest_financially: true,
                    resourfull: false,
                    cant_invest: false
                }
            });
        } else if (name === 'resourfull' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    invest_financially: false,
                    resourfull: true,
                    cant_invest: false
                }
            });
        } else if (name === 'cant_invest' && checked) {
            setFormData((prevState) => {
                return {
                    ...prevState,
                    invest_financially: false,
                    resourfull: false,
                    cant_invest: true
                }
            });
        }
    }
    
    const onMessageChangeHandler = (e) => { 
        setFormData((prevState) => {
            return {
              ...prevState,
              [e.target.name]: e.target.value
            }
        })
    }

    const HubSpotAPIExecution = (apiPayloadForHubspot) => {
        if(contactAPIFired) return;
        contactAPIFired = true;

        const url = `/hubspot-contacts`; 

        fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json' 
            },
            body: JSON.stringify(apiPayloadForHubspot)
        })
        .then(data => {  
            if (data?.id) { 
                Settings.alert('Message sent successfully!');
                contactAPIFired = true;
                setFormData({
                    qsc_yes: false,
                    qsc_no: false,
                    full_name: '',
                    member: false,
                    guardian: false,
                    age: '',
                    sport_priority_1: '',
                    sport_priority_2: '',
                    sport_priority_3: '',
                    positions: '',
                    country: '',
                    state: '',
                    major_obstacles: '',
                    physical_performance: '',
                    one_year_celebration: '',
                    about_us: '',
                    commercial_gym: false,
                    group_classes: false,
                    online_coaching: false,
                    semi_private: false,
                    pricate_one: false,
                    other: false,
                    strength_conditioning: false,
                    track_field: false,
                    speed_training: false,
                    current_occupation: '',
                    invest_financially: false,
                    resourfull: false,
                    cant_invest: false,
                    email: '',
                    phone_number: '',
                }) 
            } else if (data?.status === "error" && data?.message.includes("already exists")) { 
                Settings.alert('This email is already registered. Please use a different email.', 'error');
                contactAPIFired = false;
            } else { 
                Settings.alert('Message could not be processed!', 'error');
                contactAPIFired = false;
            }
        })
        .catch(error => {
            Settings.alert('Message couldnt Process!', 'error')
            contactAPIFired = false 
        });
    }

    const TwilioApiCall = (payloadBody) => {
        const accountSid = 'AC51a1cb5148f2e4ea95791a5a4733d692';
        const authToken = '11028ed967e7fb99d50a058f9f488470';
        const fromNumber = '+16476991144';
        const toNumber = '+16477709124';
    
        const url = `https://api.twilio.com/2010-04-01/Accounts/${accountSid}/Messages.json`;
    
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${accountSid}:${authToken}`)}`,
            },
            body: new URLSearchParams({
                From: fromNumber,
                To: toNumber,
                Body: payloadBody
            })
        })
        .then(response => {  
            console.log('successfully sented message to Donna')
        })
        .catch(error => { 
            console.log('not successfully sented message to Donna')
        });
    }

    const executeContactMessageAPI = async (emailPayload) => { 
        try {
            const response = await fetch('/trigger-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(emailPayload)
            });
            const data = await response.json();
    
            if (response.ok) {
                console.log(data.message)
            }
        } catch (error) {
            console.error('Error executing email to client!', error);
        } 
    }

    const handleSubmit = () => {
        if(!formData.full_name){
            Settings.alert('Full Name field missing!', 'error')
            return;
        }
        if(!formData.qsc_yes && !formData.qsc_no){
            Settings.alert('Quest Sports Gym field missing!', 'error')
            return;
        }
        if(!formData.guardian && !formData.member){ 
            Settings.alert('Please provide either Member or Guardian!', 'error')
            return;
        }
        if(!formData.age){
            Settings.alert('Age field missing!', 'error')
            return;
        }
        if(!formData.sport_priority_1 && 
            !formData.sport_priority_2 && 
            !formData.sport_priority_3
        ){
            Settings.alert('Please provide your Training Sports priority!', 'error')
            return;
        }
        if(!formData.positions){
            Settings.alert('Position field missing!', 'error')
            return;
        }
        if(!formData.country && !formData.state){
            Settings.alert('Please provide both Country & State!', 'error')
            return;
        }
        if(!formData.major_obstacles){
            Settings.alert('Please provide Major Obstacles field!', 'error')
            return;
        }
        if(!formData.one_year_celebration){
            Settings.alert('Please provide Celebration field!', 'error')
            return;
        }
        if(!formData.about_us){
            Settings.alert('Where did you get to know about us field is missing!', 'error')
            return;
        }
        if( !formData.commercial_gym && 
            !formData.group_classes && 
            !formData.online_coaching &&
            !formData.semi_private &&
            !formData.pricate_one &&
            !formData.other){
            Settings.alert('Please provide any of your experiences!', 'error')
            return;
        }
        if( !formData.strength_conditioning && 
            !formData.track_field && 
            !formData.speed_training){
            Settings.alert('Please provide any of your interests!', 'error')
            return;
        }
        if(!formData.current_occupation){
            Settings.alert('Your Current Occupation field is missing!', 'error')
            return;
        }
        if(!formData.invest_financially && 
            !formData.cant_invest && 
            !formData.resourfull
        ){
            Settings.alert('Your Current Occupation field is missing!', 'error')
            return;
        }
        if(!formData.email && !formData.phone_number){
            Settings.alert('Email & Phone Number fields are missing!', 'error')
            return;
        }
  
        const textTemplateForHubspot = `
            New Application SignUp - Information;
  
            Participant's Age: ${formData.age} 
            Phone: ${formData.phone_number} 
            Country/State-Province: ${formData.country} - ${formData.state}

            Other Necessary Information:

            By choosing QSC as your Sport Club, you believe that you will never need to look for another gym again. : ${formData.qsc_yes ? 'sure, I wont look for any other gym' : 'Nah, I just want to be average'}
            Applied by: ${formData.member ? 'Member/Participant' : 'Guardian'}  
            Training for which sport(s)?: 1st Priority: ${formData.sport_priority_1} - 2st Priority: ${formData.sport_priority_2} - 3st Priority: ${formData.sport_priority_3}
            Which position(s)?: ${formData.positions}
            Physical Performance / Mindset: ${formData.physical_performance}
            Major Obstacles?: ${formData.major_obstacles}
            On this day one year from now, what exactly will we be celebrating?: ${formData.one_year_celebration}
            Hearing About us?: ${formData.about_us}
            -

            Past Experiences: ${formData.commercial_gym ? 'Commercial Gym' : '-'} / ${formData.group_classes ? 'Group Classes' : '-'} / ${formData.online_coaching ? 'Online Coaching' : '-'} / ${formData.semi_private ? 'Semi Private' : '-'} / ${formData.pricate_one ? 'Private 1:1' : '-'} / ${formData.other ? 'Other' : '-'}
            Program Interested In?: ${formData.strength_conditioning ? 'Strength Conditioning' : '-'} / ${formData.track_field ? 'Track Field' : '-'} / ${formData.speed_training ? 'Speed Training' : '-'}
            Current Occupation: ${formData.current_occupation}
            Opportunity For Future investment?: ${formData.invest_financially ? 'Client Will Invest Directly' : '-'} / ${formData.resourfull ? 'Client resourceful and can access credit if needed.' : '-'} / ${formData.cant_invest ? 'Client cannot Invest' : '-'}  
            -
            
            ----
            Message from Quest Sports Club Canada.
        `;

        const textTemplateForTwilio = `
            New Application SignUp - Information;
  
            Full Name: ${formData.full_name} 
            Participant's Age: ${formData.age}  
            Phone: ${formData.phone_number} 
            Email: ${formData.email}
            Country/State-Province: ${formData.country} - ${formData.state}

            Other Necessary Information:

            By choosing QSC as your Sport Club, you believe that you will never need to look for another gym again. : ${formData.qsc_yes ? 'sure, I wont look for any other gym' : 'Nah, I just want to be average'}
            Applied by: ${formData.member ? 'Member/Participant' : 'Guardian'}  
            Training for which sport(s)?: 1st Priority: ${formData.sport_priority_1} - 2st Priority: ${formData.sport_priority_2} - 3st Priority: ${formData.sport_priority_3}
            Which position(s)?: ${formData.positions}
            Physical Performance / Mindset: ${formData.physical_performance}
            Major Obstacles?: ${formData.major_obstacles}
            On this day one year from now, what exactly will we be celebrating?: ${formData.one_year_celebration}
            Hearing About us?: ${formData.about_us}
            -

            Past Experiences: ${formData.commercial_gym ? 'Commercial Gym' : '-'} / ${formData.group_classes ? 'Group Classes' : '-'} / ${formData.online_coaching ? 'Online Coaching' : '-'} / ${formData.semi_private ? 'Semi Private' : '-'} / ${formData.pricate_one ? 'Private 1:1' : '-'} / ${formData.other ? 'Other' : '-'}
            Program Interested In?: ${formData.strength_conditioning ? 'Strength Conditioning' : '-'} / ${formData.track_field ? 'Track Field' : '-'} / ${formData.speed_training ? 'Speed Training' : '-'}
            Current Occupation: ${formData.current_occupation}
            Opportunity For Future investment?: ${formData.invest_financially ? 'Client Will Invest Directly' : '-'} / ${formData.resourfull ? 'Client resourceful and can access credit if needed.' : '-'} / ${formData.cant_invest ? 'Client cannot Invest' : '-'}  
            -
            
            ----
            Message from Quest Sports Club Canada.
        `;

        const apiPayloadForContact = { 
            to: formData.email, 
            subject: '*Application Received*', 
            message:
            `
            The following message is to confirm you, that we have received your application successfully, within 24-48 hours, our team will contact you. 
            
            Thank you for your patience!
            `
        };

        const payload = {
            properties: {
                email: formData.email,
                firstname: formData.full_name,
                lastname: "", 
                client_details: textTemplateForHubspot?.trim()
            }
        };
 
        HubSpotAPIExecution(payload);
        TwilioApiCall(textTemplateForTwilio);
        executeContactMessageAPI(apiPayloadForContact);
    };
    return (
        <div className='enquery_contact_component' id='contact-form'>
            <div className='container'>
                <div className='queries_info text-center mb-5'>
                    <h1>Quest Sports Application</h1>
                    <p> To clarify, you are applying to QUEST SPORTS CANADA for athletes. You are completing this form to enhance your physical, cognitive, and mental performance to gain an edge.</p>
                </div>

                <div className='row form-fields'>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields checkboxes'>
                        <label>By choosing QSC as your sports group, you believe that you will never need to look for another gym again?<span className='asterik_sign'>*</span></label>
                        <label>
                            <input onChange={onInputChangeHandler} checked={formData.qsc_yes} name='qsc_yes' type='checkbox' />
                            <span>Yes - I'm serious about reaching my dreams!</span>
                        </label>
                        <label>
                            <input onChange={onInputChangeHandler} checked={formData.qsc_no} name='qsc_no' type='checkbox' />
                            <span>Nah - I just want to be average.</span>
                        </label>
                    </div> 
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>Can you provide information on the physical performance, mindset, and nutrition habits of the participant(s)?</label>
                        <div>
                            <textarea value={formData.physical_performance} onChange={onMessageChangeHandler} name='physical_performance' id='physical_performance' placeholder='Your Physical Performance/Mindset' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>To start, may I get your name?<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.full_name} name='full_name' type='text' id='full_name' placeholder='Full Name' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>On this day one year from now, what exactly will we be celebrating?<span className='asterik_sign'>*</span></label>
                        <div><h6>Think deep {formData.full_name}. Imagine yourself one year from now, what does success look like? What will we be celebrating?</h6></div>
                        <div>
                            <textarea value={formData.one_year_celebration} onChange={onMessageChangeHandler} name='one_year_celebration' id='one_year_celebration' placeholder='Your Thoughts' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields checkboxes'>
                        <label>are you applying as the:<span className='asterik_sign'>*</span></label>
                        <div> 
                            <div> 
                                <label>
                                    <input onChange={onInputChangeHandler} checked={formData.member} name='member' type='checkbox' />
                                    <span>Participant/Member</span>
                                </label>
                            </div>
                            <div> 
                                <label>
                                    <input onChange={onInputChangeHandler} checked={formData.guardian} name='guardian' type='checkbox' />
                                    <span>Parent/Guardian</span>
                                </label>
                            </div>
                        </div>
                    </div> 
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>How did you hear about us?<span className='asterik_sign'>*</span></label>
                        <div><h6>Google search, Instagram, and/or from a friend? (If a friend referred you, please let us know their name. This can increase your chances of being accepted.)</h6></div>
                        <div>
                            <textarea value={formData.about_us} onChange={onMessageChangeHandler} name='about_us' id='about_us' placeholder='How do you know about us?' />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>What is the participant's age?<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.age} name='age' type='text' id='age' placeholder={`Participant's Age`} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>Please share any experiences you have had<span className='asterik_sign'>*</span></label> 
                        <div><h6>Select all the applies. Choose as many as you like</h6></div>
                        <div className='checkbox-types'>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.commercial_gym} name='commercial_gym' type='checkbox' />
                                <span>Open/Commercial Gym Memberships</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.group_classes} name='group_classes' type='checkbox' />
                                <span>Group Classes</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.online_coaching} name='online_coaching' type='checkbox' />
                                <span>Online Coaching</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.semi_private} name='semi_private' type='checkbox' />
                                <span>Semi-Private Coaching</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.pricate_one} name='pricate_one' type='checkbox' />
                                <span>Private 1-on-1 Coaching</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.other} name='other' type='checkbox' />
                                <span>Other</span>
                            </label>
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Training for which sport(s)?<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.sport_priority_1} name='sport_priority_1' type='text' id='sport_priority_1' placeholder='Your Sport Or Training 1st Priority' />
                        </div>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.sport_priority_2} name='sport_priority_2' type='text' id='sport_priority_2' placeholder='Your Sport Or Training 2nd Priority' />
                        </div>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.sport_priority_3} name='sport_priority_3' type='text' id='sport_priority_3' placeholder='Your Sport Or Training 3rd Priority' />
                        </div>
                    </div>   
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Kindly share with us the details of the program that has captured your interest or in which you are currently enrolled.<span className='asterik_sign'>*</span></label>
                        <div><h6>Select all the applies. Choose as many as you like</h6></div>
                        <div className='checkbox-types'>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.strength_conditioning} name='strength_conditioning' type='checkbox' />
                                <span>Strength & Conditioning (OLY WeightLifting Club), {`Semi Private`} - {`Team Training`}</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.track_field} name='track_field' type='checkbox' />
                                <span>Track & Field (Quest Sports Track & Field Club), {`Elementary - Running, Jumping, Throwing`}, {`High School - Running, Jumping, Throwing`}</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} value={formData.speed_training} name='speed_training' type='checkbox' />
                                <span>Speed Training (Speed)</span>
                            </label> 
                        </div>
                    </div> 
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Which position(s)?<span className='asterik_sign'>*</span></label>
                        <div><h6>Sports such as tennis and gymnastics may not require a position. If that is the case, you may skip or leave this question blank.</h6></div>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.positions} name='positions' type='text' id='positions' placeholder='Position' />
                        </div> 
                    </div>  
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>Could you please share your current occupation and how long you have been working in this field?<span className='asterik_sign'>*</span></label>
                        <div><h6>School, work, and sports can all impact stress, scheduling, and commitment. Understanding these obstacles helps us tackle them together.</h6></div>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.current_occupation} name='current_occupation' type='text' id='current_occupation' placeholder={`Current Occupation`} />
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>In which city, province/state, and country are you currently residing?<span className='asterik_sign'>*</span></label> 
                        <div>
                            <CountryPicker country={formData.country} setCountryProvince={setFormData} state={formData.state} />
                        </div> 
                    </div> 
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>If you had the opportunity to achieve your dreams, obtain the desired outcomes, and acquire the skills to maintain those outcomes for life, would you be willing to invest in your aspirations and work with the coaches at Quest Sports Canada?<span className='asterik_sign'>*</span></label>
                        <div className='checkbox-types'>
                            <label>
                                <input onChange={onInputChangeHandler} checked={formData.invest_financially} name='invest_financially' type='checkbox' />
                                <span>I can invest financially.</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} checked={formData.resourfull} name='resourfull' type='checkbox' />
                                <span>I'm resourceful and can access credit if needed</span>
                            </label>
                            <label>
                                <input onChange={onInputChangeHandler} checked={formData.cant_invest} name='cant_invest' type='checkbox' />
                                <span>I can't invest due to limited income and no access to credit.</span>
                            </label> 
                        </div>
                    </div>
                    <div className='col-lg-6 col-12 mb-4 questions'>
                        <label>What are the major obstacles?<span className='asterik_sign'>*</span></label>
                        <div>
                            <textarea value={formData.major_obstacles} onChange={onMessageChangeHandler} name='major_obstacles' id='major_obstacles' placeholder='Your Major Obstacles' />
                        </div>
                    </div> 
                    <div className='col-lg-6 col-12 mb-3 forms-input-fields'>
                        <label>{formData.full_name}, please share your contact details to proceed smoothly. Thank you for your cooperation<span className='asterik_sign'>*</span></label>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.email} name='email' type='text' id='email' placeholder={`Email`} />
                        </div>
                        <div>
                            <input onChange={onInputChangeHandler} value={formData.phone} name='phone' type='text' id='phone' placeholder={`Phone Number`} />
                        </div>
                    </div>
                    <div className='col-12'> 
                        <button onClick={handleSubmit} onMouseLeave={() => setBtnHover(false)} onMouseEnter={() => setBtnHover(true)} className='skew-btn'>SUBMIT <span><img className='img-fluid ml-2' src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span></button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default ApplicationForm;