import React, { useState } from 'react';
import './Hero.css';
import { Swiper, SwiperSlide } from 'swiper/react'; 
import { Link } from 'react-router-dom';

const Hero = () => {
  const [btnHover, setBtnHover] = useState(false);
  const [heroContent, setHeroContent] = useState({ 
    heading: `Building Tomorrow's`,
    splitHeading: `Champions Today`,
    creds: '',
    paragraph: '',
    postion: 'left',
    type: ''
  })

  const imageUrls = [
    '/images/creatives/home-hero.png',  
    '/images/creatives/speed_training_home_banner.jpg',  
    '/images/creatives/home_banner_stadium.jpg',  
  ];

  const renderImageBasedOnMode = (currImage) => {
    if(currImage == '/images/creatives/home-hero.png' && window.innerWidth <= 620) return '/images/creatives/home-hero-mob.png';
    if(currImage == '/images/creatives/speed_training_home_banner.jpg' && window.innerWidth <= 620) return '/images/creatives/speed_training_home_banner.jpg';
    if(currImage == '/images/creatives/home_banner_stadium.jpg' && window.innerWidth <= 620) return '/images/creatives/home_banner_stadium.jpg';
    
    return currImage;
  };
 
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{
        delay: 2000
      }}
      effect="fade"
      onSwiper={(swiper) => {
        swiper.on('slideChange', (evt) => {   
          if(evt.activeIndex == 3 || evt.activeIndex == 0) {  
            setHeroContent({ 
              heading: 'Indoor Track & Field',
              creds: ``,
              paragraph: '',
              postion: 'center',
              type: 'indoor'
            }) 
          } 
          else if (evt.activeIndex == 2) {
            setHeroContent({ 
              heading: 'SPEED TRAINING',
              creds: ``,
              paragraph: '',
              postion: 'center',
              type: 'baseball'
            }) 
          }
          else { 
            setHeroContent({ 
              heading: `Building Tomorrow's`,
              splitHeading: `Champions Today`,
              creds: '',
              paragraph: '',
              postion: 'left',
              type: ''
            })
          }
        });
      }}
    >
      {imageUrls.map((imageUrl, index) => (
        <SwiperSlide key={index}>
          <div
            id='quest_slider'
            style={{ backgroundImage: `url(${renderImageBasedOnMode(imageUrl)})` }}
            className='quest_sp_banner_component d-flex justify-content-start align-items-center'
          >
            <div className='wrapper-div container'> 
              <div className='container slide-in'>
                <div className='row'>
                  <div className={`col-12 text-lg-${heroContent.postion} text-center`}> 
                      <h1>{heroContent.heading} <br /> {heroContent.splitHeading}</h1>
                      <h3 style={{color: '#fff'}}> <div dangerouslySetInnerHTML={{ __html: heroContent.creds }} /> </h3>
                      <p style={{fontWeight: 600}}>{heroContent.paragraph}</p>
                      
                      { !heroContent.type && 
                        (
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              if(heroContent.postion === 'center') return;
                              document.querySelector('#contact-form').scrollIntoView({
                                behavior: 'smooth',
                              });
                            }}
                            onMouseLeave={(e) => setBtnHover(false)}
                            onMouseEnter={() => setBtnHover(true)}
                            className='skew-btn mt-4'
                          > 
                            Inquire Now
                            <span>
                              <img
                                className='img-fluid ml-2'
                                src={!btnHover ? '/images/icons/golden-torch.svg' : '/images/icons/golden-torch.svg'}
                              />
                            </span>
                          </button>
                        )
                      }
                      { heroContent.type == 'baseball' && 
                        (
                          <Link
                            to={'/speed-training-program'}
                          >
                            <button className='skew-btn'>Inquire Now</button>
                          </Link>
                        )}
                        { heroContent.type == 'indoor' && 
                        (
                          <Link
                            to={'/track-field-club'}
                          >
                            <button className='skew-btn'>Inquire Now</button>
                          </Link>
                        )}  
                    </div> 
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Hero;