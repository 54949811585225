export const slides = [
  {
    id: 1,
    className: 'normal_w',
    image: "/images/associations/mta.svg",
  },
  {
    id: 2,
    className: 'normal_w',
    image: "/images/associations/track-field.svg",
  },
  {
    id: 3,
    className: 'normal_w',
    image: "/images/associations/athletics-ontario.svg",
  },
  {
    id: 4,
    className: 'normal_w',
    image: "/images/associations/athletics.svg",
  },
  {
    id: 5,
    className: 'bnc_logo',
    image :'/images/creatives/partner-1.png'
  }, 
  {
    id: 6,
    className: 'opportunity_logo',
    image :'/images/associations/partner-2.png'
  },  
];