import React from 'react';
import './Events.css';

const Events = () => {
  return (
    <div className='choose_events'>
        <div className='events_info text-center'>
            <h1>CHOOSE YOUR EVENTS</h1>
            <p className='mb-5'>The Quest Sports Track & Field Club offers a variety of options for the seasoned athlete or an absolute beginner.</p>
        </div>
        <div className="container-fluid">
            <div className="box">
                <div className='box_info'>
                    <h1 className='mb-3'>SPECIALTY</h1>
                    <p>Heptathlon</p>
                    <p>Decathlon</p>
                    <p>4 x 100 Relay</p>
                    <p>4 x 400 Relay</p>
                </div>
            </div>
            <div className="box">
                <div className='box_info'>
                    <h1 className='mb-3'>SPRINT's</h1>
                    <p>60 meter</p>
                    <p>100 meter</p>
                    <p>200 meter</p>
                    <p>400 meter</p>
                </div>
            </div>
            <div className="box">
                <div className='box_info'>
                    <h1 className='mb-3'>HURDLES</h1>
                    <p>100 meter H</p>
                    <p>110 meter H</p>
                    <p>300 meter H</p>
                    <p>400 meter H</p>
                </div>
            </div>
            <div className="box">
                <div className='box_info'>
                    <h1 className='mb-3'>JUMPS</h1>
                    <p>Long Jump</p>
                    <p>High Jump</p>
                    <p>Triple Jump</p>
                    <p>Pole Vaul</p>
                </div>
            </div>
            <div className="box">
                <div className='box_info'>
                    <h1 className='mb-3'>DISTANCE</h1>
                    <p>800 meters</p>
                    <p>Middle Distance</p>
                    <p>Cross Country</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Events;