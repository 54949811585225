import React, {useEffect, useState, Fragment} from 'react'
import Loader from '../Loader/Loader';

const Appointment = () => {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const AppointmentModule = () => {
    return (
      <div className='appointment_booking m-0 p-0'>
        <iframe width="100%" height="1000" src="https://site.arboxapp.com/schedule?identifier=CyvWUKzO&amp;whitelabel=Quest-Sports&amp;referer=PLUGIN&amp;lang=en&amp;scheduleType=appointment"></iframe>
      </div>
    )
  }
  
  return (
    <Fragment>
      {loading ? <Loader /> : AppointmentModule()}
    </Fragment>
  )
}

export default Appointment;