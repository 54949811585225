export const ElementaryMembershipArray =  [
  {
    id: 1,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "COMBINED EVENTS ELEMENTARY",
    buttonText: "REGISTER NOW"
  },
  {
    id: 2,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "CROSS COUNTRY ELEMENTARY",
    buttonText: "REGISTER NOW"
  },
  {
    id: 3,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY JAVELIN",
    buttonText: "REGISTER NOW"
  },
  {
    id: 4,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY LONG JUMP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 5,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY HIGH JUMP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 6,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY HURDLES",
    buttonText: "REGISTER NOW"
  },
  {
    id: 7,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY POLE VAULT",
    buttonText: "REGISTER NOW"
  },
  {
    id: 8,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY TRACK SPRING MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 9,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY TRACK SUMMER MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 10,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY TRACK FALL MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 11,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "ELEMENTARY TRACK WINTER MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 12,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "RETURNING MEMBERS ELEMENTARY (CURRENT MEMBERS ONLY)",
    buttonText: "REGISTER NOW"
  },
  {
    id: 13,
    type: 'track_field_elementary',
    icon: "/images/icons/shoe.svg",
    heading1: "RETURNING MEMBERS ELEMENTARY (backup registration) (CURRENT MEMBERS ONLY)",
    buttonText: "REGISTER NOW"
  }
];
 
export const HighSchoolMembershipArray = [
  {
    id: 1,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "COMBINED EVENTS HIGH SCHOOL",
    buttonText: "REGISTER NOW"
  },
  {
    id: 2,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "CROSS COUNTRY HIGH SCHOOL",
    buttonText: "REGISTER NOW"
  },
  {
    id: 3,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL JAVELIN",
    buttonText: "REGISTER NOW"
  },
  {
    id: 4,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL LONG JUMP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 5,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL HIGH JUMP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 6,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL HURDLES",
    buttonText: "REGISTER NOW"
  },
  {
    id: 7,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL POLE VAULT",
    buttonText: "REGISTER NOW"
  },
  {
    id: 8,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL TRACK SPRING MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 9,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL TRACK SUMMER MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 10,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL TRACK FALL MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 11,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "HIGHSCHOOL TRACK WINTER MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 12,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "RETURNING MEMBERS MIDDLE SCHOOL & HIGH SCHOOL (COMPETITIVE)",
    buttonText: "REGISTER NOW"
  },
  {
    id: 13,
    type: 'track_field_highschool',
    icon: "/images/icons/shoe.svg",
    heading1: "RETURNING MEMBERS MIDDLE SCHOOL & HIGH SCHOOL (COMPETITIVE) (backup registration)",
    buttonText: "REGISTER NOW"
  }
];

export const SpeedTrainingMembershipArray = [
  {
    id: 1,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "360 ATHLETIC",
    buttonText: "REGISTER NOW"
  },
  {
    id: 2,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "SPEED TRAINING BRONZE MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 3,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "SPEED TRAINING SILVER MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 4,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "SPEED TRAINING GOLD MEMBERSHIP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 5,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "GROUP TRAINING SEMI PRIVATE UP TO 3",
    buttonText: "REGISTER NOW"
  },
  {
    id: 6,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "GROUP TRAINING SEMI PRIVATE UP TO 5",
    buttonText: "REGISTER NOW"
  },
  {
    id: 7,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "GROUP TRAINING SMALL GROUP UP TO 10",
    buttonText: "REGISTER NOW"
  },
  {
    id: 8,
    type: 'speed_training',
    icon: "/images/icons/running.svg",
    heading1: "GROUP TRAINING SPORTS TEAM UP TO 15",
    buttonText: "REGISTER NOW"
  }
];

export const StrengthConditioningMembershipArray = [
  {
    id: 1,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "STRENGTH & CONDITIONING FOR TRACK & FIELD QUEST A HP",
    buttonText: "REGISTER NOW"
  },
  {
    id: 2,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "STRENGTH & CONDITIONING FOR TRACK & FIELD QUEST B",
    buttonText: "REGISTER NOW"
  },
  {
    id: 3,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "STRENGTH & CONDITIONING FOR ATHLETES 2x/wk",
    buttonText: "REGISTER NOW"
  },
  {
    id: 4,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "STRENGTH & CONDITIONING FOR ATHLETES 3x/wk",
    buttonText: "REGISTER NOW"
  },
  {
    id: 5,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "STRENGTH & CONDITIONING FOR ATHLETES UNLIMITED",
    buttonText: "REGISTER NOW"
  },
  {
    id: 6,
    type: 'strength_conditioning',
    icon: "/images/icons/dumble.svg",
    heading1: "OLY KIDS WLC",
    buttonText: "REGISTER NOW"
  }
];
