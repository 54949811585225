import React, {Fragment, useState} from 'react';
import './PerformanceGroup.css'
import { TrainingSessions } from './Sessions';
import { Link } from 'react-router-dom';

const PerformanceGroup = ({targetSection}) => {
    const [btnHover, setBtnHover] = useState(Array(TrainingSessions.length).fill(false));
  return (
    <div className='performance_group_component'>
        <div className='container-fluid'>
            <div className='performance_group_sessions text-center mb-5'>
                <h1>PERFORMANCE GROUP TRAINING SESSIONS</h1>
            </div> 
        </div>
        <div className='container'> 
            <div className='row'>
                {TrainingSessions.map((card, index) => ( 
                    <Fragment>
                        {card.div && <div className={card.div}></div>}
                        <div className={card.responsive}>
                            <div key={card.id} className="card text-center align-items-center mb-5">
                                <div className='row text-center'> 
                                    <div className='col-12'>
                                        <img src={card.image} className='img-fluid thumbnail-icon mb-3' alt={card.title} />
                                    </div>
                                    <div className='col-12'>
                                        <div className='group_sessions_info mb-5'>
                                            <h1>{card.sessionType}</h1> 
                                            <h2>{card.heading}</h2> 
                                            <p>{card.paragraph}</p> 
                                        </div>  
                                    </div>
                                    <div className='col-12 mb-auto'> 
                                        <a href={targetSection}>
                                            <button
                                                onMouseEnter={() => {
                                                    const newBtnHover = [...btnHover];
                                                    newBtnHover[index] = true;
                                                    setBtnHover(newBtnHover);
                                                }}
                                                onMouseLeave={() => {
                                                    const newBtnHover = [...btnHover];
                                                    newBtnHover[index] = false;
                                                    setBtnHover(newBtnHover);
                                                }}
                                                className='skew-btn mb-4 mt-3'
                                            >
                                                REGISTER
                                                <span><img className='img-fluid join-btn ml-2' src={!btnHover[index] ? '/images/icons/golden-torch.svg' : '/images/icons/black-torch.svg'} /></span>
                                            </button> 
                                        </a> 
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    </div>
  )
}

export default PerformanceGroup;