import React, { useEffect, useState } from 'react';
import './Registrations.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core'; 

SwiperCore.use([Autoplay, Navigation, Pagination]); 

const ElementaryMembership = ({membershipArray, mainText}) => {
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1100);
    let timeoutId;

    useEffect(() => { 
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1100);
        }; 
        window.addEventListener('resize', handleResize); 
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const fetchCheckoutSessionURL = async (type, id) => {
        try {
            const response = await fetch("/create-checkout-session", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "items": [
                        {
                            "type": type,
                            "id": id,     
                            "quantity": 1   
                        } 
                    ]
                })
            });
            const data = await response.json();
  
            if (response.ok) {
                localStorage.setItem('session_id', data.session_id); 
                const newTab = window.open(data.url, '_blank'); 
                if(newTab) newTab.focus(); else window.location.href = data.url;
            }
        } catch (error) {
            console.error('Error creating checkout session', error);
        }
    }

    const onRegisterButtonClickedHandler = (type, id) => {
        if(!type && !id) return;
        if (timeoutId) clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            fetchCheckoutSessionURL(type, id);
        }, 300); 
    }

    return (
        <div className='elementary_membership'>
            <div className='signup_text text-center'> <h1>{mainText}</h1> </div>
 
            {isSmallScreen ? (
                <div className='container-fluid'>
                    <Swiper
                        spaceBetween={30}
                        slidesPerView={3}
                        loop={true}
                        autoplay={{ delay: 1000 }}
                        className='membership-swiper-registration'
                        speed={500} 
                        pagination={{ clickable: true }}
                        breakpoints={{
                            280: {
                                slidesPerView: 1,
                            },
                            340: {
                                slidesPerView: 1,
                            },
                            440: {
                                slidesPerView: 1,
                            },
                            540: {
                                slidesPerView: 1,
                            },
                            600: {
                                slidesPerView: 1,
                            },
                            640: {
                                slidesPerView: 2,
                            },
                            768: {
                                slidesPerView: 3,
                            },
                            1024: {
                                slidesPerView: 3,
                            },
                        }}
                    >
                        {membershipArray.map((item, index) => (
                            <SwiperSlide key={index}>
                                <div key={item.id} className="swiper-box">
                                    <div className='icon'> <img src={item.icon} className='img-fluid' /> </div>
                                    <div className="heading">{item.heading1}</div>
                                    <div className="sub-heading">{item.heading2}</div>
                                    <div className="sub-heading">{item.schedule}</div>
                                    <button className='skew-btn' onClick={() => onRegisterButtonClickedHandler(item.type, item.id)}>{item.buttonText}</button>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            ) : (
                <div className='container-fluid ps-2 pe-2'> 
                    <div className="elementary_membership_list">
                        {membershipArray.map((item, index) => (
                            <div key={item.id} className="box">
                                <div className='icon'> <img src={item.icon} className='img-fluid' /> </div>
                                <div className="heading">{item.heading1}</div>
                                <div className="sub-heading">{item.heading2}</div>
                                <div className="sub-heading">{item.schedule}</div>
                                <button className='skew-btn' onClick={() => onRegisterButtonClickedHandler(item.type, item.id)}>{item.buttonText}</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}  
        </div>
    );
};

export default ElementaryMembership;