import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import LocationListings from './LocationListings';

const LocationScreen = () => {
    return (
        <Fragment>
            <Helmet>
                <meta name="robots" content="noindex" />
            </Helmet>
             <div className="container text-center py-5">
                <h2>Location Pages</h2>
                <p>Quest Sport Location Page Portal</p>
            </div>
            <LocationListings />
        </Fragment>
    )
};

export default LocationScreen;