import React from 'react';
import './BlogsComp.css';

const InnerBlogSection = ({data , apiData=null}) => {
  return (
    <div className='inner_blogs_section'>
        <div className='container-fluid'> 
        {
            apiData
            ?
            <>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <h2>{apiData.title}</h2>
                </div>
                <div className='col-lg-8 col-12 text-center text-lg-left'>
                    <h3>PUBLISHED: <span>{apiData.published_date} </span></h3>
                </div>
                {/*  <div className='col-lg-4 col-12 text-center text-lg-left'>
                    <h4>
                        SHARE: 
                        <span>
                           <ul>
                              <li><img className='img-fluid' src='/images/icons/fb-blog.svg' /></li>
                              <li><img className='img-fluid' src='/images/icons/twitter-blog.svg' /></li>
                              <li><img className='img-fluid' src='/images/icons/mail-blog.svg' /></li>
                           </ul>
                        </span>
                    </h4>
                </div> */}
                <div className="line mb-3"></div>
            </div>
            <div className='row'>
                <div className='col-12 text-left mb-1'>
                    <div className="para-normal" dangerouslySetInnerHTML={{ __html: apiData.brief_paragraph }}></div>
                </div>
                {apiData.blogs_content.map((item) => (
                <>
                    
                    <div className='col-12 text-left mb-1'>
                        <h2 className="para-heading" >{item.heading}</h2>
                    </div>
                    <div className='col-12 text-left mb-1'>
                        <div className="para-normal" dangerouslySetInnerHTML={{ __html: item.paragraph }}></div>
                    </div>
                </>
                ))}
            </div>
            </>
            :
            <>
            <div className='row'>
                <div className='col-12 mb-3'>
                    <h2>{data[0].mainHeading}</h2>
                </div>
                <div className='col-lg-8 col-12 text-center text-lg-left'>
                    <h3>PUBLISHED: <span>{data[0].dates}  {data[0].time}</span></h3>
                </div>
                {/*  <div className='col-lg-4 col-12 text-center text-lg-left'>
                    <h4>
                        SHARE: 
                        <span>
                           <ul>
                              <li><img className='img-fluid' src='/images/icons/fb-blog.svg' /></li>
                              <li><img className='img-fluid' src='/images/icons/twitter-blog.svg' /></li>
                              <li><img className='img-fluid' src='/images/icons/mail-blog.svg' /></li>
                           </ul>
                        </span>
                    </h4>
                </div> */}
                <div className="line mb-3"></div>
            </div>
            <div className='row'>
                <div className='col-12 text-left mb-1'>
                    <div className="para-normal" dangerouslySetInnerHTML={{ __html: data[0].mainParagraph }}></div>
                </div> 
                <div className='col-12 text-left mb-1 mt-2'>
                    
                    {
                        data[1].heading &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[1].heading }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    {
                        data[1].paragraph &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[1].paragraph }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].heading &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].heading }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].paragraph &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[2].paragraph }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].subHeading1 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].subHeading1 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].paragraph1 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html:data[2].paragraph1 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    {
                        data[2].subHeading2 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].subHeading2 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].paragraph2 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[2].paragraph2 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                   
                    {
                        data[2].subHeading3 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].subHeading3 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].paragraph3 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[2].paragraph3 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].subHeading4 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].subHeading4 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                   
                    {
                        data[2].paragraph4 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[2].paragraph4 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].subHeading5 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[2].subHeading5 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[2].paragraph5 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[2].paragraph5 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                   
                    {
                        data[3].heading &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].heading }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].subHeading1 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].subHeading1 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph1 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph1 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].subHeading2 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].subHeading2 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph2 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph2 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].subHeading3 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].subHeading3 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph3 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph3 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].subHeading4 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].subHeading4 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph4 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph4 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].subHeading5 &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].subHeading5 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paragraph5 &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paragraph5 }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-1'>
                    
                    {
                        data[3].paraHeading &&
                        <div className="para-heading" 
                        dangerouslySetInnerHTML={{ __html: data[3].paraHeading }}
                        ></div>
                    }
                </div>
                <div className='col-12 text-left mb-0'>
                    
                    {
                        data[3].paraBottom &&
                        <div className="para-normal" 
                        dangerouslySetInnerHTML={{ __html: data[3].paraBottom }}
                        ></div>
                    }
                </div>
            </div>
            </>
        }
            
        </div>
    </div>
  )
}

export default InnerBlogSection;