import React from 'react';
import InnerBlogSection from './InnerBlogSection';
import './BlogsComp.css'; 
import { useNavigate } from 'react-router-dom';

const BlogInnerWrapper = ({data, recentBlogs , apiData=null}) => {  
    const navigate = useNavigate()

    const handleBlogClick = (blogId) => {
        navigate(`/blogs/${blogId}`)
        window.location.reload()
    }
    
    return (
        <div className='blog_inner_wrapper'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-8'>
                        <InnerBlogSection data={data} apiData={apiData}/>
                    </div>
                    <div className='col-lg-4 pt-5'>
                        <div className='col-12 mb-4'>
                            <h1>RECENT NEWS</h1>
                            <div class="line mb-3"></div>
                        </div>
                        <div className='col-12 p-0 m-0 rcnt_blg' onClick={() => handleBlogClick(recentBlogs.recentBlogs.blog1_str_id)}>
                            <div className='container p-0 m-0'>
                                <div className='row ps-0 m-0'>
                                    <div className='col-7 ps-0 m-0'>
                                        <img className='img-fluid w-100' src='/images/creatives/article-2.svg' />
                                    </div>
                                    <div className='col-5'>
                                        <h5>{recentBlogs.recentBlogs.blog1}</h5>
                                        <p>{recentBlogs.dates} <span className='ms-3'>{recentBlogs.time}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-0 m-0 rcnt_blg' onClick={() => handleBlogClick(recentBlogs.recentBlogs.blog2_str_id)}>
                            <div className='container p-0 m-0'>
                                <div className='row ps-0 m-0'>
                                    <div className='col-7 ps-0 m-0'>
                                        <img className='img-fluid w-100' src='/images/creatives/article-2.svg' />
                                    </div>
                                    <div className='col-5'>
                                        <h5>{recentBlogs.recentBlogs.blog2}</h5> 
                                        <p>{recentBlogs.dates} <span className='ms-3'>{recentBlogs.time}</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BlogInnerWrapper;