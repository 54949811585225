import React, { useState } from 'react';
import './CountryPicker.css';

const CanadaProvinces = [
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland and Labrador',
    'Nova Scotia',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Northwest Territories',
    'Nunavut',
    'Yukon',
];
  
const USAStates = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
];

const CountryPicker = ({country, state, setCountryProvince}) => {
    const [selectedCountry, setSelectedCountry] = useState(country);
    const [selectedProvince, setSelectedProvince] = useState(state);
  
    const handleCountryChange = (e) => {
        setCountryProvince((prevState) => {
            return {
                ...prevState,
                country: e.target.value
            }
        })
        setSelectedCountry(e.target.value); 
    };
  
    const handleProvinceChange = (e) => {
        setCountryProvince((prevState) => {
            return {
                ...prevState,
                state: e.target.value
            }
        }) 
        setSelectedProvince(e.target.value);
    };
  
    return (
        <div className='country_picker'>
            <label htmlFor="country">Select Country: </label>
            <select id="country" value={selectedCountry} onChange={handleCountryChange}>
                <option value="Canada"> Canada</option>
                <option value="USA"> USA</option>
            </select>
    
            {selectedCountry === 'Canada' || selectedCountry === '' && (
                <div>
                    <label htmlFor="province">Select Province: </label>
                    <select id="province" value={selectedProvince} onChange={handleProvinceChange}>
                        <option value="">Select</option>
                        {CanadaProvinces.map((province) => (
                            <option key={province} value={province}> {province} </option>
                        ))}
                    </select>
                </div>
            )} 

            {selectedCountry === 'USA' && (
                <div>
                    <label htmlFor="province">Select Province:</label>
                    <select id="province" value={selectedProvince} onChange={handleProvinceChange}>
                        <option value="">Select</option>
                        {USAStates.map((province) => (
                            <option key={province} value={province}>
                            {province}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    );
};
  
export default CountryPicker;