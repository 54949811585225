import React, {useState, useEffect, useRef, Fragment} from 'react';
import './RadialImgContent.css';
import { Link } from 'react-router-dom';

// flex-row-reverse - for reversing an order

const RadialImgContent = ({reverse, targetSection, contact = false, heading, removeBtn = false, programsRoute, anotherParagraph, paragraph, requiredPara = false, buttonIcon, hoverBtnIcon, buttonTitle, imgUrl, listItems, buttonRoute}) => {
  const ref = useRef(null);
  const [btnHover, setBtnHover] = useState(false);
  const [isIntersecting, setIsIntersecting] = useState(false);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsIntersecting(true);
          observer.disconnect();
        }
      },
      {
        threshold: 0.2,
      }
    );

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const DirectToEnquery = (e) => { 
    e.preventDefault();
    if(contact) {
      return;
    }
    document.querySelector('#contact-form').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const AnimationFromLeft = isIntersecting ? 'slide-out' : '';
  const AnimationFromRight = isIntersecting ? 'slide-in' : '';

  return (
    <div className='img_effect'>
      <div className= {`radial_img_parent`} ref={ref}>
        <div className='radial_img_content_component'>
          <div className='container-fluid p-0'>
            <div className={`row d-flex m-0 ms-0 me-0 align-items-center gx-5`}>
              <div className={`col-md-12 position-relative order-2 order-lg-1 col-12 col-lg-7 mt-3 ${AnimationFromRight}`}>
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-1 col-0'></div>
                    <div className='col-lg-11 col-12'>
                      <h2 className='mb-3 slide-down mb-lg-4 col-12-text-center'>{heading}</h2>
                      <p className={`mb-3 slide-down mb-lg-4 col-12-text-center`}>{paragraph}</p>
                      <p className={`mb-3 slide-down mb-lg-4 col-12-text-center`}>{anotherParagraph}</p>
                      <div className='mt-2 slide-down col-12-text-center'>
                        {listItems ? listItems.map(element => {return (<p className='list-item-para'>{element.name} <span>{element.title}</span></p>)}) : null}
                        {buttonTitle && (
                          <Fragment>
                            {buttonRoute && !removeBtn && ( 
                              // <Link to={buttonRoute}>
                                <button onClick={DirectToEnquery} onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                                  {buttonTitle}{' '}
                                  <span> 
                                    <img className='img-fluid ml-2' src={!btnHover ? buttonIcon : hoverBtnIcon } />
                                  </span>
                                </button> 
                              // </Link> 
                            )} 
                            {!buttonRoute && !removeBtn && '/appointment-booking' && ( 
                              <button onClick={DirectToEnquery} onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                                {buttonTitle}{' '}
                                <span> 
                                  <img className='img-fluid ml-2' src={!btnHover ? buttonIcon : hoverBtnIcon } />
                                </span>
                              </button> 
                            )}
                            {programsRoute && removeBtn &&
                              <Fragment>
                                <a href={targetSection}>
                                  <button onMouseEnter={() => setBtnHover(true)} onMouseLeave={() => setBtnHover(false)} className='skew-btn'>
                                    {buttonTitle}{' '}
                                    <span> 
                                      <img className='img-fluid ml-2' src={!btnHover ? buttonIcon : hoverBtnIcon } />
                                    </span>
                                  </button>
                                </a>
                              </Fragment>
                            }
                          </Fragment>
                        )}
                      </div>
                      {requiredPara && <div className={`${requiredPara ? 'hide_p' : ''}`}></div>}
                    </div>
                  </div>
                </div>
              </div>
              <div className={`col-md-12 hover-col p-0 m-0 order-1 d-flex justify-content-end align-items-center order-lg-2 col-12 col-lg-5 position-relative ${AnimationFromLeft}`}> 
                <img src={imgUrl} className='img-fluid img-icon' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RadialImgContent;