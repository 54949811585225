export const TwitterTweets = [
    {
        id: 1,
        tweet: '1660274462606995458'
    },
    {
        id: 2,
        tweet: '1655996268684902400'
    },
    {
        id: 3,
        tweet: '1651470879094718464'
    },
    {
        id: 4,
        tweet: '1650650548008284171'
    },
    {
        id: 5,
        tweet: '1650273571959197697'
    },
    {
        id: 6,
        tweet: '1644316431922724871'
    },
    {
        id: 7,
        tweet: '1641304920241446913'
    }, 
    {
        id: 8,
        tweet: '1677812188189425665'
    }, 
    {
        id: 9,
        tweet: '1678236313072410626'
    }, 
    {
        id: 10,
        tweet: '1688004612174086144'
    }, 
    {
        id: 11,
        tweet: '1687979385763094528'
    }, 
    {
        id: 12,
        tweet: '1688000266883452928'
    }, 
    {
        id: 13,
        tweet: '1688005505803821056'
    }, 
    {
        id: 14,
        tweet: '1687975407784747009'
    }
]

