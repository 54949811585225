import React, { Fragment, useEffect, useState } from 'react';
import './QuestAccomplishment.css';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import { AccomplishmentsArray } from '../../../components/Constants/QuestAccomplishments';
import { NewAccomplishmentsArray } from '../../../components/Constants/NewQuestAccomp';

SwiperCore.use([Autoplay, Navigation, Pagination]);

const QuestAccomplishment = () => { 
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 992);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 992);
        };

        window.addEventListener('resize', handleResize);

        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    const desktopSwiper = () => {
        return (
            <div id='quest_accomplishments_section' className='quest_accomplishments sections-bg'>
                <div className='quest_bg'></div>
    
                <div className='container quest_accomplishments_list'>
                    <div className='quest_info text-center mb-5'>
                        <h1>QUEST SPORTS ACCOMPLISHMENTS</h1>
                    </div>  
                    <div className='swiper-accomp'>
                        <Swiper  
                            loop={true}
                            autoplay={{ delay: 1000 }}
                            speed={1000} 
                            navigation={false}
                            pagination={{ clickable: true }}
                        >
                            {NewAccomplishmentsArray.map((item, index) => (
                                <SwiperSlide key={index} className='mb-5'>
                                    <div className='swiper-slide-group mb-3'>
                                        <div className='row g-4 py-lg-5'>
                                            <div className='col-lg-4 accomplishments_col'>
                                                <div className='acomplishments'>
                                                    <img className='img-fluid mb-4' src='/images/icons/trophy.svg' />
                                                    <h1 className='year'>{item.year_1.year}</h1>
                                                    <h2 className='year_subtitle'>{item.year_1.subtitle}</h2>
                                                    {item.year_1.listItems && item.year_1.listItems.map((listItem, listItemIndex) => (
                                                    <div className='mb-3' key={listItemIndex}>
                                                        {listItem.childArray && listItem.childArray.map((element, elementIndex) => (
                                                            <p className='list-item-accomp' key={elementIndex}>{element}</p>
                                                        ))}
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='col-lg-4 accomplishments_col'>
                                                <div className='acomplishments featured'>
                                                    <img className='img-fluid mb-4' src='/images/icons/trophy.svg' />
                                                    <h1 className='year'>{item.year_2.year}</h1>
                                                    <h2 className='year_subtitle'>{item.year_2.subtitle}</h2>
                                                    {item.year_2.listItems && item.year_2.listItems.map((listItem, listItemIndex) => (
                                                    <div className='mb-3' key={listItemIndex}>
                                                        {listItem.childArray && listItem.childArray.map((element, elementIndex) => (
                                                        <p className='list-item-accomp' key={elementIndex}>{element}</p>
                                                        ))}
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>

                                            <div className='col-lg-4 accomplishments_col'>
                                                <div className='acomplishments'>
                                                    <img className='img-fluid mb-4' src='/images/icons/trophy.svg' />
                                                    <h1 className='year'>{item.year_3.year}</h1>
                                                    <h2 className='year_subtitle'>{item.year_3.subtitle}</h2>
                                                    {item.year_3.listItems && item.year_3.listItems.map((listItem, listItemIndex) => (
                                                    <div className='mb-3' key={listItemIndex}>
                                                        {listItem.childArray && listItem.childArray.map((element, elementIndex) => (
                                                        <p className='list-item-accomp' key={elementIndex}>{element}</p>
                                                        ))}
                                                    </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div> 
            </div> 
        )
    }

    const mobileSwiper = () => {
        return (
            <div className='mob-slider'>
            <div className='quest_bg'></div>
                <div className="slider-wrapper-accomplishments"> 
                    
                    <div className="container">
                        <div className='accomplishments_info text-center mb-5'>
                            <h1>QUEST SPORTS ACCOMPLISHMENTS</h1>
                        </div>
                        <div className='text-center'> 
                            <Swiper  
                                slidesPerView={1}
                                loop={true}
                                autoplay={{ delay: 1000 }}
                                speed={1000} 
                                navigation={false}
                                pagination={{ clickable: true }} 
                            >
                                {AccomplishmentsArray.map((card) => (
                                    <SwiperSlide className='mb-5 mb-lg-0' key={card.id}>
                                        <div className={`card mb-5 p-3`}>
                                            <div className='row text-center'>
                                                <div className='col-12 pt-3'> 
                                                    <div className='accomplishments_info'>
                                                        <img src={card.logo} className='img-fluid accomplishment_logo' />
                                                        <h1 className='year'>{card.year}</h1> 
                                                        <h2 className='year_subtitle'>{card.subtitle}</h2>
                                                        {card.listItems && card.listItems.map((item) =>  {
                                                            return ( 
                                                                <div className='mb-3'>
                                                                    {item.childArray.map((element) => (
                                                                        <p className='list-item-accomp'>{element}</p>
                                                                    ))}
                                                                </div> 
                                                            )
                                                        })}
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                ))}
                            </Swiper> 
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (  
        <Fragment>
            {isMobile ? mobileSwiper() : desktopSwiper()}
        </Fragment>
    )
}

export default QuestAccomplishment;