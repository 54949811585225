import React, {useState} from 'react';
import './TrainingHub.css';
import { TrainingStudents } from './Students';
import PopupGallery from './PopupGallery';

let CurrentImgUrl = null

const TrainingHub = () => {
    const [showPopup, setShowPopup] = useState(false)

    const ClickImageHandler = (imgUrl) => {
        CurrentImgUrl = imgUrl
        setShowPopup(true)
    }

  return (
    <div className='training_hub_students'>
        <div className='container'>
            <div className='row text-center'>
                <div className='col-12'>
                    <h1>WELCOME TO QUEST SPORTS <span>PERFORMANCE</span></h1>
                </div>
            </div>
            <div className='row d-flex justify-content-center align-items-center'>
                {TrainingStudents.map(element => {
                    return ( 
                        <div className='col-lg-4 col-md-6 col-12 pr-0 pr-md-5 pr-lg-5 pb-5 p-0 m-0'>
                            <img onClick={() => ClickImageHandler(element.img)} className='img-fluid gallery-img w-100 p-0 m-0' src={element.img} />
                        </div>
                    )
                })}
            </div>
        </div>
        {showPopup && <PopupGallery imagesArr={TrainingStudents} img={CurrentImgUrl} setClosePopup={setShowPopup} />}
    </div>
  )
}

export default TrainingHub;