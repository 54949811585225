import { Routes, Route } from 'react-router-dom';  
import Enquery from './components/Enquiries/Enquery';
import Footer from './components/Footer/Footer';
import GoogleReviews from './components/Google Clients/GoogleReviews';
import Header from './components/Header/Header';
import About from './screens/About';
import ScrollToTop from './components/Scroll To Top/ScrollToTop';
import Home from './screens/Home';
import TrackField from './screens/TrackField';
import {BaseballReviews} from './components/Google Clients/Reviews';
import SpeedTraining from './screens/SpeedTraining';
import Programs from './screens/Programs';
import Blogs from './screens/Blogs';
import StrengthConditioning from './screens/StrengthConditioning';
import ContactUs from './screens/ContactUs';
import React,{ Fragment, useEffect } from 'react';
import InnerBlog from './components/Blogs/InnerBlog';
import Appointment from './components/Appointment/Appointment';
import AppointmentSchedule from './components/Appointment/AppointmentSchedule';
import Events from './screens/Events';
import { TestimonialsClients } from './components/Google Clients/Testimonials';
import TrackFieldLandingPage from './screens/Landing Pages/TrackFieldLandingPage';
import PrivacyPolicy from './screens/PrivacyPolicy';
import UserDeletion from './screens/UserDeletion';
import UserCheckout from './screens/Landing Pages/UserCheckout';
import ClientPaymentRoute from './screens/Landing Pages/ClientPaymentRoute';
import Register from './screens/Register';
import Application from './screens/Application';
import Schedule from './screens/Schedule';
import News from './components/News-Articles/News';
import DonationPaymentRoute from './screens/Payment Gateways/DonationPaymentRoute';

import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./components/LocationPages_/LocationPages"

const CommonComponents = ({ children }) => { 
  return (
    <Fragment>
      <Header />
        {children}
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/track-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        />
        <Enquery />
      <Footer />
    </Fragment>
  );
};

const ScheduleComponent = ({ children }) => {
  return (
    <Fragment>
      <Header />
        {children}
        <Enquery />
        <News />
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/track-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        /> 
      <Footer /> 
    </Fragment>
  )
}

const ApplicationComponent = ({ children }) => { 
  return (
    <Fragment>
      <Header />
        {children}
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/track-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        />
      <Footer />
    </Fragment>
  );
};

const PaymentGatewayComponent = ({ children }) => { 
  return (
    <Fragment> 
        {children}
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/track-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        />
    </Fragment>
  );
};

const PrivacyPolicyLayout = ({children}) => {
  return (
    <Fragment>
      <Header />
      {children}
      <Footer />
    </Fragment>
  )
}

const HomeSpeedLayout = ({ children }) => {
  return (
    <Fragment>
      <Header />
        {children}
        <GoogleReviews 
          text={'Canadian Baseball Testimonials'} 
          backgroundImage={'/images/creatives/home-speed-clients.png'}
          reviewsArr={BaseballReviews}
          loop={false}
         />
        <Enquery />
      <Footer />
    </Fragment>
  );
};

const StrengthConditioningLayout = ({ children }) => {
  return (
    <Fragment>
      <Header />
        {children}
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/str-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        />
        <Enquery />
      <Footer />
    </Fragment>
  );
};

const ContactLayout = ({ children }) => {
  return (
    <Fragment>
      <Header contact={true} />
        {children}
      <Footer />
    </Fragment>
  );
};

const LandingPagesLayout = ({children}) => {
  return (
    <Fragment>
      <Header landingPage={true} />
        {children}
        <GoogleReviews 
          text={'Testimonials'} 
          backgroundImage={'/images/creatives/track-clients.png'} 
          reviewsArr={TestimonialsClients}
          loop={true}
        />
      <Footer />
    </Fragment>
  )
}

const NotFound = () => {
  return (
    <h1>NOT FOUND!</h1>
  )
}

const AppointmentLayout = ({ children }) => {
  return (
    <Fragment> 
        {children}
      <Footer />
    </Fragment>
  );
};
// routes:
const App = () => {
  // useEffect(() => {
  //   const handleRightClick = (event) => {
  //     event.preventDefault(); 
  //   };
  //   document.addEventListener('contextmenu', handleRightClick);
  //   return () => {
  //     document.removeEventListener('contextmenu', handleRightClick);
  //   };
  // }, []);
  return (
    <ScrollToTop> 
      <Routes>
        <Route path="/" element={<CommonComponents><Home /></CommonComponents>} />
        <Route path="/home" element={<HomeSpeedLayout><Home /></HomeSpeedLayout>} />
        <Route path="/about" element={<CommonComponents><About /></CommonComponents>} />
        <Route path="/track-field-club" element={<CommonComponents><TrackField /></CommonComponents>} />
        <Route path="/speed-training-program" element={<HomeSpeedLayout><SpeedTraining /></HomeSpeedLayout>} />
        <Route path="/programs" element={<CommonComponents><Programs /></CommonComponents>} />
        <Route path="/strength-conditioning-gym" element={<StrengthConditioningLayout><StrengthConditioning /></StrengthConditioningLayout>} />
        <Route path="/blogs" element={<CommonComponents><Blogs /></CommonComponents>} />
        <Route path="/news" element={<CommonComponents><Events /></CommonComponents>} />
        <Route path="/blogs/:id" element={<CommonComponents><InnerBlog /></CommonComponents>} />
        <Route path="/appointment-booking" element={<AppointmentLayout><Appointment /></AppointmentLayout>} />
        <Route path="/schedule" element={<ScheduleComponent><Schedule /></ScheduleComponent>} />
        <Route path="/contact-us" element={<ContactLayout><ContactUs /></ContactLayout>} />
        <Route path="/privacy-policy" element={<PrivacyPolicyLayout><PrivacyPolicy /></PrivacyPolicyLayout>} />
        <Route path="/user-data-deletion-policy" element={<PrivacyPolicyLayout><UserDeletion /></PrivacyPolicyLayout>} />
        <Route path='/register-for-membership' element={<PaymentGatewayComponent><Register /></PaymentGatewayComponent>} />
        <Route path='/application-for-registration' element={<ApplicationComponent><Application /></ApplicationComponent>} />

        {/* Landing Page Routes - */}
        <Route path="/become-an-olympian" element={<LandingPagesLayout><TrackFieldLandingPage /></LandingPagesLayout>} />

        {/* User registration form + Payment gateways*/} 
        <Route path='/nextgen-donation' element={<DonationPaymentRoute />} />
        <Route path='/payment-checkout' element={<ClientPaymentRoute />} />
        <Route path='/successful-checkout' element={<UserCheckout />} />

        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
 
        {/* Wildcard Route - */}
        <Route path="*" element={<NotFound />}/>
      </Routes> 
    </ScrollToTop>
  );
}

export default App;