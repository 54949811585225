import React from 'react';  
import './Associations.css'; 
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import { Slides } from './L_pageSlides';

SwiperCore.use([Autoplay, Navigation, Pagination]);

/*
props:
Slides: array, []
  {
    id: int,
    image: ''
  }
*/

const AssociationSlider = ({translate = 'Y'}) => {
  return (
    <div className='associations_swiper_slider_landing' style={{ transform: translate == 'Y' ? 'translateY(-37%)' : ( translate == 'X' ? 'translateY(20%)' : '' ) }}>
      <div className="container-fluid">
        <div className="row">
          <div className='col-12 d-flex justify-content-center mb-3'>
            <h2>ASSOCIATIONS</h2>
          </div>
          <div className="col-lg-10 col-md-10 col-sm-12 mx-auto d-flex justify-content-center align-items-center">
            <Swiper
                className="asso_swiper"
                spaceBetween={30}
                slidesPerView={1}
                slidesPerGroup={1}
                loop={false}
                autoplay={{ delay: 1000 }}
                speed={500}
                breakpoints={{
                    640: {
                    slidesPerView: 1,
                    },
                    768: {
                    slidesPerView: 2,
                    },
                    1024: {
                    slidesPerView: 4,
                    },
                }}
                onInit={(swiper) => { 
                    if (swiper.slides.length === 4) {
                    swiper.allowSlidePrev = false;
                    swiper.allowSlideNext = false;
                    }
                }}
                onSlideChange={(swiper) => { 
                    if (swiper.slides.length < 4 && !swiper.loopedSlides) {
                    swiper.loopedSlides = 4 - swiper.slides.length;
                    swiper.updateSlides();
                    swiper.allowSlidePrev = true;
                    swiper.allowSlideNext = true;
                    }
                }}
            >
                {Slides.map((slide) => (
                    <SwiperSlide key={slide.id}>
                    <img src={slide.image} className={slide.className} alt={`Slide ${slide.id}`} />
                    </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
} 
export default AssociationSlider;